import React from 'react';

const BlogViewModal = ({ blog, onClose }) => {
  if (!blog) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-y-auto">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl m-4 h-[500px] overflow-y-scroll">
        <button onClick={onClose} className="float-right text-2xl">&times;</button>
        
        <img src={blog.blogImage} alt={blog.title} className="w-full h-64 object-cover rounded-lg mb-4" />
        
        <h2 className="text-3xl font-bold mb-4">{blog.title}</h2>

        <div className="mb-4">
          <span className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded">{blog.category}</span>
        </div>
        
        <div className="bg-gray-100 p-4 rounded-lg mb-6">
          <p className="text-gray-600">{blog.sortParagraph}</p>
        </div>
        
        <div className="space-y-4 mb-6">
          {blog.paragraphs.map((paragraph, index) => (
            <p key={index} className="text-gray-700">{paragraph}</p>
          ))}
        </div>
        
        <div className="flex items-center justify-between border-t pt-4">
          <div className="flex items-center">
            <img src={blog.authorImage} alt={blog.authorName} className="w-12 h-12 rounded-full mr-4" />
            <div>
              <p className="font-semibold">{blog.authorName}</p>
            </div>
          </div>
          <p className="text-sm text-gray-500">{new Date(blog.createdDate).toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogViewModal;