function capitalizeFirstLetter(text) {
    // Check if text is defined and is a string
    if (typeof text !== 'string' || text.length === 0) {
        // If text is not a string or is empty, return it unchanged
        return text;
    }

    // Convert the first character to uppercase and concatenate it with the rest of the string
    return text.charAt(0).toUpperCase() + text.slice(1);
}

// Export the function
export { capitalizeFirstLetter };
