import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const BlogCard = ({ blog, onView, onEdit, onDelete, isEditLoading, isDeleteLoading }) => {
  return (
    <div className="border rounded-lg overflow-hidden shadow-lg">
      <img src={blog.blogImage} alt={blog.title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold mt-2">{blog.title}</h3>
        <p className="text-gray-600 mt-2">{blog.sortParagraph}</p>
        <span className="text-sm text-gray-500">Category : {blog.category}</span>
        <div className="flex items-center mt-4">
          <img src={blog.authorImage} alt={blog.authorName} className="w-10 h-10 rounded-full mr-3" />
          <div>
            <p className="font-semibold">{blog.authorName}</p>
            <p className="text-sm text-gray-500">{new Date(blog.createdDate).toLocaleDateString()}</p>
          </div>
        </div>
        <div className="mt-4 flex justify-between border-t-2 border-gray-300 pt-4">
        <button onClick={onView} className="bg-green-500 text-white px-4 py-1 rounded">
          View
        </button>
       <div className='flex gap-2'>
       <button onClick={onEdit} className="bg-blue-500 text-white px-4 py-1 rounded" disabled={isEditLoading}>
          {isEditLoading ? 'Loading...' : 'Edit'}
        </button>
        <button onClick={onDelete} className="bg-red-500 text-white px-4 py-1 rounded flex items-center justify-center w-[80px]" disabled={isDeleteLoading}>
          {isDeleteLoading ? <CircularProgress size={18}/> : 'Delete'}
        </button>
       </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;