"use client";
import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { FaMinus, FaPlus } from "react-icons/fa6";
import UserHeader from "../../components/common/UserHeader";
import frame from '../../assets/frame.svg';

const Faqs = () => {
    const ques = [
      {
        question: "Q. I’d like to sell my property. What are the documents I need to provide a buyer?",
        answer: "The original Sale Deed, Title Deed, pertinent tax receipts, and Encumbrance Certificate could be requested by a buyer."
      },
      {
        question: "Q. Who pays the Stamp Duty, the buyer or the seller?",
        answer: "Only the buyer pays the Stamp Duty."
      },
      {
        question: "Q. Is there a process or form that has to be filled out before the Sale Deed or Transfer Document can be executed?",
        answer: "Yes. Depending on where the property is located, the process and forms may differ from state to state. According to the registration regulations, each state in India has developed its own set of forms. When registering a sale deed or transfer deed, this paperwork must be completed and submitted. Both the buyer and the seller are required by the Income Tax Act and its regulations for a sale transaction to supply their PAN card numbers, and in the event of a sale, either the buyer or the seller would need to complete Form 60 of the Income Tax. The person would not be required to file Form 60 of the Income Tax if they are a Non-Resident Indian (NRI) who is not assessed for taxes in India."
      },
      {
        question: "Q. Do legal paperwork for property sales have to be registered?",
        answer: "Yes. You can complete it at the district’s sub-registrar’s office."
      },
      {
        question: "Q. When does a residential property sale become official?",
        answer: "If the seller has received the full purchase price, the documents have been registered, and the buyer has been given physical possession of the property, the sale of a residential property is said to have been formalized."
      },
      {
        question: "Q. What help does Deep Real provide to property brokers?",
        answer: "On the home page of our website, there is an enquiry form where you can list the properties that are up for sale. You might connect with a large number of buyers by signing up with us as a broker without having to pay any additional fees."
      },
      {
        question: "Q. How soon after I list my property for sale would I get a call from you?",
        answer: "If you had submitted your requirements between 9 am and 10 pm, we would have called you within 15 minutes of receiving your inquiry. If not, we would get in contact with you the following day."
      },
      {
        question: "Q. How does Deep Real help if I want to sell my property?",
        answer: "You must complete an inquiry form, which is available on our website’s front page, in order to sell your property."
      }
    ];

  const [openIndex, setOpenIndex] = useState(0);

  const toggleAnswer = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };
  return (
   <>
     <UserHeader
        headerImg={frame}
        pageName="Faq's"
        mainText="Home"
      />
    <div className="px-10 md:py-10">
      <h1 className="text-center md:text-4xl text-2xl font-medium text-primaryColor md:mb-10 mb-5">Frequently Asked Questions</h1>
      <div className="md:w-[70%] m-auto flex flex-col justify-start items-start gap-2">
        {ques.map((faq, index) => (
          <div key={index} className="mb-1 py-2 rounded-md w-full ">
            <div className="flex items-center">
              <button
                onClick={() => toggleAnswer(index)}
                className="text-[14px] font-semibold text-left px-3 flex items-center justify-between gap-4 w-full border rounded-md text-primaryColor bg-gray-200 py-4"
              >
                <p>{faq.question}</p>
                {openIndex === index ? (
                  <div className="bg-primaryColor text-white px-2 py-2 rounded">
                    <FaMinus />
                  </div>
                ) : (
                  <div className="bg-primaryColor text-white px-2 py-2 rounded">
                    <FaPlus />
                  </div>
                )}
              </button>
            </div>
            <Collapse in={openIndex === index}>
              <div className="pt-2 pb-0 border tracking-wider bg-white">
                {
                  // Ensure faq.answer is an array
                  Array.isArray(faq.answer) ? (
                    faq.answer.map((answer, idx) => (
                      <div key={idx} className="flex gap-2 px-4 ">
                        <p>
                          {idx + 1}. {answer}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="flex gap-2 px-4 ">
                      <p>{faq.answer}</p>
                    </div>
                  )
                }
                <div className="bg-primaryColor h-2 w-full rounded-b mt-4"></div>
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    </div>
   </>
  );
};

export default Faqs;
