import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const LandAddForm = () => {
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        generalInfo: {
            propertyName: '',
            propertyType: '',
            currentStatus: ''
        },
        locationDetails: {
            address: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        },
        physicalCharacteristics: {
            totalArea: '',
            terrainType: '',
            soilType: '',
            roadAccess: ''
        },
        financialInfo: {
            sellingPrice: ''
        },
        utilities: {
            waterAccess: '',
            sewageSystem: '',
            electricityAccess: ''
        },
        propertyImages: []
    });

    const handleClear = () => {
        setFormData({
            generalInfo: {
                propertyName: '',
                propertyType: '',
                currentStatus: ''
            },
            locationDetails: {
                address: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
            },
            physicalCharacteristics: {
                totalArea: '',
                terrainType: '',
                soilType: '',
                roadAccess: ''
            },
            financialInfo: {
                sellingPrice: ''
            },
            utilities: {
                waterAccess: '',
                sewageSystem: '',
                electricityAccess: ''
            },
            propertyImages: []
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log(formData)

        // Check if any field is empty
        const isEmpty = Object.values(formData).some(field => {
            if (typeof field === 'object' && !Array.isArray(field)) {
                return Object.values(field).some(subField => subField === '');
            } else if (Array.isArray(field) && field.length === 0) {
                return true;
            }
            return field === '';
        });

        if (isEmpty) {
            toast.warning('Please fill all the fields.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/lands/add-land`, formData);
            // console.log(response.data);
            if (response.data) {
                setIsLoading(false)
                handleClear()
                toast.success('Land added successfully')
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error);
            toast.error(error);
        }
    };




    const handleChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');
        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
    };

    const handleImageChange = async (e) => {
        const imageFile = e.target.files[0];
        setImageUploadLoading(true)
        try {
            const formDataCloudinary = new FormData();
            formDataCloudinary.append('file', imageFile);
            formDataCloudinary.append('upload_preset', 'Assavio Lands');

            const cloudinaryResponse = await axios.post(
                'https://api.cloudinary.com/v1_1/dhysboq8w/image/upload',
                formDataCloudinary

            );
            const cloudinaryUrl = cloudinaryResponse?.data?.secure_url;
            if (cloudinaryResponse?.data?.secure_url) {
                setImageUploadLoading(false)
            }
            // console.log(cloudinaryUrl);
            setFormData(prevState => ({
                ...prevState,
                propertyImages: [...prevState.propertyImages, cloudinaryUrl]
            }));
        } catch (error) {
            console.error('Error submitting form:', error);
            setImageUploadLoading(false)
        }
    };

    const handleRemoveImage = (index) => {
        setFormData(prevState => ({
            ...prevState,
            propertyImages: prevState.propertyImages.filter((_, i) => i !== index)
        }));
    };


    return (
        <div className="container mx-auto px-6">
            <ToastContainer />
            {imageUploadLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
            )}
            <h2 className="text-2xl font-normal ml-6 mt-4 mb-4">Add Land</h2>
            <form onSubmit={handleSubmit} className="w-full border p-6 h-[85vh] overflow-y-scroll rounded-xl">
                {/* General Info */}
                <div className="mb-6">
                    <h1 className='text-2xl mb-2 text-blue-500'>General Info</h1>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Property Name
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Property Name"
                        name="generalInfo.propertyName"
                        value={formData.generalInfo.propertyName}
                        onChange={handleChange}
                    />
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Property Type
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Property Type"
                        name="generalInfo.propertyType"
                        value={formData.generalInfo.propertyType}
                        onChange={handleChange}
                    />
                    {/* Current Status */}
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Current Status
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Current Status"
                        name="generalInfo.currentStatus"
                        value={formData.generalInfo.currentStatus}
                        onChange={handleChange}
                    />
                </div>

                {/* Location Details */}
                <div className="mb-6">
                    <h1 className='text-2xl mb-2 text-blue-500'>Locations Details</h1>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Address
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Address"
                        name="locationDetails.address"
                        value={formData.locationDetails.address}
                        onChange={handleChange}
                    />
                    {/* City */}
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        City
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="City"
                        name="locationDetails.city"
                        value={formData.locationDetails.city}
                        onChange={handleChange}
                    />
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        State
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="State"
                        name="locationDetails.state"
                        value={formData.locationDetails.state}
                        onChange={handleChange}
                    />
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Zip Code
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="number"
                        placeholder="Zip Code"
                        name="locationDetails.zipCode"
                        value={formData.locationDetails.zipCode}
                        onChange={handleChange}
                    />
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Country
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Country"
                        name="locationDetails.country"
                        value={formData.locationDetails.country}
                        onChange={handleChange}
                    />
                </div>

                {/* Physical Characteristics */}
                <div className="mb-6">
                    <h1 className='text-2xl mb-2 text-blue-500'>Physical Characteristics </h1>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Total Area
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="number"
                        placeholder="Total Area"
                        name="physicalCharacteristics.totalArea"
                        value={formData.physicalCharacteristics.totalArea}
                        onChange={handleChange}
                    />
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Terrain Type
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Terrain Type"
                        name="physicalCharacteristics.terrainType"
                        value={formData.physicalCharacteristics.terrainType}
                        onChange={handleChange}
                    />
                    {/* Soil Type */}
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Soil Type
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Soil Type"
                        name="physicalCharacteristics.soilType"
                        value={formData.physicalCharacteristics.soilType}
                        onChange={handleChange}
                    />
                    {/* Road Access */}
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Road Access
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Road Access"
                        name="physicalCharacteristics.roadAccess"
                        value={formData.physicalCharacteristics.roadAccess}
                        onChange={handleChange}
                    />
                </div>

                {/* Financial Info */}
                <div className="mb-6">
                    <h1 className='text-2xl mb-2 text-blue-500'>Financial Info</h1>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Selling Price
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="number"
                        placeholder="Selling Price"
                        name="financialInfo.sellingPrice"
                        value={formData.financialInfo.sellingPrice}
                        onChange={handleChange}
                    />
                </div>

                {/* Utilities */}
                <div className="mb-6">
                    <h1 className='text-2xl mb-2 text-blue-500'>Utilities</h1>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Water Access
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Water Access"
                        name="utilities.waterAccess"
                        value={formData.utilities.waterAccess}
                        onChange={handleChange}
                    />
                    {/* Sewage System */}
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Sewage System
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Sewage System"
                        name="utilities.sewageSystem"
                        value={formData.utilities.sewageSystem}
                        onChange={handleChange}
                    />
                    {/* Electricity Access */}
                    <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                        Electricity Access
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Electricity Access"
                        name="utilities.electricityAccess"
                        value={formData.utilities.electricityAccess}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-6">
                    <h1 className='text-2xl mb-2 text-blue-500'>Property Images</h1>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        multiple
                    />

                    <div className={`${formData?.propertyImages?.length < 1 && 'hidden'} flex flex-wrap gap-4 mt-4 border p-2`}>
                        {
                            formData?.propertyImages.map((ele, index) => (
                                <div key={index}>
                                    <img src={ele} alt="Property Image" className='w-16 h-16' />
                                    <button onClick={() => handleRemoveImage(index)} className='text-center w-full'>Remove</button>
                                </div>
                            ))
                        }
                    </div>

                </div>

                {/* Property Images */}
                {/* <div className="mb-6">
                    <h1 className='text-2xl mb-2 text-blue-500'>Property Images</h1>                    
                </div> */}

                {/* Submit Button */}
                <div className="flex items-center justify-center">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        {isLoading ? "Loading..." : "Add Building"}
                    </button>
                </div>

            </form>
        </div>
    );
};

export default LandAddForm;
