import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const NonAuthLayout = (props) => {
    // const navigate = useNavigate();
    // const [isAuthenticated, setIsAuthenticated] = useState(false);

    // useEffect(() => {
    //     const adminInfo = localStorage.getItem('adminInfo');
    //     if (adminInfo) {
    //         setIsAuthenticated(true);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         navigate('/dashboard');
    //     } else {
    //         navigate('/login');
    //         // return
    //     }
    // }, [isAuthenticated, navigate]);

    //   let userRole = decryptData()?.user?.role;

      if (localStorage.getItem('adminInfo')) {
        return (
          <Navigate to={{ pathname: '/dashboard' }} />
        );
      }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default NonAuthLayout