import React, { useEffect } from "react";
import UserHeader from "../../../components/common/UserHeader";
import group1 from "../../../assets/investments.png";
import { FaArrowRight } from "react-icons/fa";
import { InvestmentServices } from "./OurTopServices";
import { useNavigate } from "react-router-dom";

const InvestmentService = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Assavio |  INVESTMENT SERVICE`;
}, []);

  return (
    <React.Fragment>
      <UserHeader pageName="Service Detail" mainText="Home" />
      <div className="p-10">
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div>
            <h1 className="lg:text-[40px] text-lg text-[#212121] font-semibold tracking-wide lg:leading-10 lg:w-[80%]">
              INVESTMENT SERVICE
            </h1>
          </div>
          <div>
            <img src={group1} alt="group1" />
          </div>
        </div>

        <div className="flex text-[16px] text-[#8B8989] tracking-wider mt-4 text-justify">
          Our investment services are tailored to help you identify and
          capitalize on lucrative investment opportunities in the real estate
          market. Here's how we can assist you:
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Market Analysis :
            </span>{" "}
            Gain a competitive edge with our in-depth market analysis and
            insights. We'll provide you with valuable information on market
            trends, emerging opportunities, and potential risks to help you make
            informed investment decisions.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Property Selection :
            </span>{" "}
            Finding the right investment property is crucial to your success.
            Our team will work closely with you to understand your investment
            goals, preferences, and risk tolerance. We'll then identify and
            evaluate investment properties that align with your objectives,
            ensuring that you make strategic investments that maximize your
            returns.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Financial Analysis :
            </span>{" "}
            Assessing the financial viability of an investment is essential. Our
            experts will conduct thorough financial analysis, including cash
            flow projections, return on investment calculations, and risk
            assessments, to help you evaluate the potential returns and risks
            associated with each investment opportunity. With our insights, you
            can confidently make sound investment decisions.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Property Management :
            </span>{" "}
            Once you've acquired an investment property, we offer comprehensive
            property management services to help you optimize your investment
            returns and minimize your workload. From tenant screening and rent
            collection to property maintenance and financial reporting, our
            experienced property management team will handle all aspects of
            property management, allowing you to focus on growing your
            investment portfolio. With our investment services, you'll have
            access to the expertise, resources, and support you need to navigate
            the real estate market with confidence and achieve your investment
            goals. Contact us today to learn more about how we can help you
            build and grow your real estate investment portfolio.
          </p>
        </div>

        <div className="mt-4">
          <h1 className="lg:text-[40px] text-2xl text-[#212121] font-semibold tracking-wide w-[80%]">
            Related Services
          </h1>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1  justify-between gap-8 mt-4">
          {InvestmentServices?.map((ele, index) => (
            <div
              key={index}
              onClick={() => {
                navigate(ele?.path);
                window.scrollTo(0, 0);
              }}
              className="relative border rounded-xl overflow-hidden cursor-pointer group"
            >
              <img
                className="m-auto h-[250px] w-full"
                src={ele?.image}
                alt={ele?.title + " " + "Image"}
              />
              <div className="absolute inset-0 flex flex-col justify-between p-4 lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:-translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center">{ele?.title}</p>
                </div>
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center line-clamp-2">{ele?.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InvestmentService;
