import Login from "../screens/nonAuth/Login";
import Register from "../screens/nonAuth/Register";
import Dashboard from "../screens/auth/Dashboard";
import Home from "../screens/users/Home";
import Setting from "../screens/auth/Setting";
import About from "../screens/users/About";
import Contact from "../screens/users/Contact";
import Community from "../screens/users/HappyCustomers/Community";
import Services from "../screens/users/Services/Services";
import PropertySellingService from "../screens/users/Services/PropertySellingService";
import SupportService from "../screens/users/Services/SupportService";
import InvestmentService from "../screens/users/Services/InvestmentService";
import Gallery from "../screens/users/HappyCustomers/Gallery";
import Testimonials from "../screens/users/HappyCustomers/Testimonials";
import ForgotPassword from "../screens/nonAuth/ForgotPassword";
import Properties from "../screens/users/Properties";
import Career from "../screens/users/Career";
import BuildingListTable from "../screens/auth/BuildingListTable";
import BuldingAddForm from "../screens/auth/BuldingAddForm";
import LandListTable from "../screens/auth/LandListTable";
import LandAddForm from "../screens/auth/LandAddForm";
import Profile from "../screens/auth/Profile";
import BuildingDetails from "../screens/users/BuildingDetails";
import LandsDetail from "../screens/users/LandsDetail";
import UpcomingProject from "../screens/users/UpcomingProject";
import JobApplications from "../screens/auth/JobApplications";
import UpdateBuilding from "../screens/auth/UpdateBuilding";
import ErrorPage from "../screens/ErrorPage";
import Blog from "../screens/users/Blog";
import BlogDetail from "../screens/users/BlogDetail";
import Faqs from "../screens/users/Faqs";
import TermAndConditions from "../screens/users/TermAndConditions";
import Blogs from "../screens/auth/Blogs";

export const authRoutes = [
  {
    path: '/dashboard',
    component: <Dashboard />,
  },
  {
    path: '/setting',
    component: <Setting />,
  },
  {
    path: '/buildings',
    component: <BuildingListTable />,
  },
  {
    path: '/create-new-building',
    component: < BuldingAddForm />,
  },
  {
    path: '/lands',
    component: < LandListTable />,
  },
  {
    path: '/create-new-land',
    component: < LandAddForm />,
  },
  {
    path: '/profile',
    component: < Profile />,
  },
  {
    path: '/job-applications',
    component: < JobApplications />,
  },
  {
    path: '/update-building/:id',
    component: < UpdateBuilding />,
  },{
    path: '/blogs',
    component: < Blogs />,
  }
];

export const nonAuthRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/register',
    component: <Register />,
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />,
  }
];

export const userRoutes = [
  {
    path: '/',
    component: <Home />,
  },
  {
    path: '/about',
    component: <About />,
  },
  {
    path: '/contact',
    component: <Contact />,
  },
  {
    path: '/career',
    component: <Career />,
  },
  {
    path: '/communities',
    component: <Community />,
  },
  {
    path: '/gallery',
    component: <Gallery />,
  },
  {
    path: '/testimonials',
    component: <Testimonials />,
  },
  {
    path: '/discover-properties',
    component: <Services />,
  },
  {
    path: '/selling-service',
    component: <PropertySellingService />
  },
  {
    path: '/investment-services',
    component: <InvestmentService />
  },
  {
    path: '/plot-sale-support',
    component: <SupportService />
  },
  {
    path: '/properties',
    component: <Properties />, 
  },
  {
    path: '/property/:title/:id',
    component: <BuildingDetails />,
  },
  {
    path: '/lands/:id',
    component: <LandsDetail />,
  },
  {
    path: '/upcoming-projects',
    component: <UpcomingProject />,
  },
  {
    path: '/all-blogs',
    component: <Blog />,
  },
  {
    path: '/:id/blog/:title',
    component: <BlogDetail />,
  },
  {
    path: '/faqs',
    component: <Faqs />,
  },
  {
    path: '/term-and-conditions',
    component: <TermAndConditions />,
  },
  {
    path: '/*',
    component: <ErrorPage />,
  },
];