import React, { useEffect, useState } from 'react';
import { FaBuilding } from "react-icons/fa";
import { MdOutlineLandslide } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import video from '../../assets/propertyVideo.mp4'

const Dashboard = () => {
    const [buildingCount, setBuildingCount] = useState(0);
    const [landCount, setLandCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);

    const targetCounts = {
        buildings: 500,
        lands: 800,
        customers: 100
    };

    useEffect(() => {
        const countToTarget = (currentCount, targetCount, setter) => {
            const increment = Math.ceil(targetCount / 100); // Adjust the increment for speed
            if (currentCount < targetCount) {
                const timeout = setTimeout(() => {
                    setter(prevCount => Math.min(prevCount + increment, targetCount));
                }, 150);
                return () => clearTimeout(timeout);
            }
        };

        countToTarget(buildingCount, targetCounts.buildings, setBuildingCount);
        countToTarget(landCount, targetCounts.lands, setLandCount);
        countToTarget(customerCount, targetCounts.customers, setCustomerCount);
    }, [buildingCount, landCount, customerCount, targetCounts]);

    return (
        <div className="min-h-screen bg-gray-100 px-10 py-5 md:px-10">
            <header className="text-3xl font-bold text-gray-800 mb-5">Dashboard</header>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <div className="bg-red-200 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-6 py-8 ">
                    <div className='flex items-center justify-between'>
                        <div className='bg-red-400 rounded-full p-4'>
                            <FaBuilding size={60} />
                        </div>
                        <div className='text-right flex flex-col gap-4'>
                            <h2 className="text-2xl font-semibold text-gray-700">
                                Total Buildings
                            </h2>
                            <p className="text-3xl font-medium text-red-500">
                                {buildingCount}+
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-green-200 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-6 py-8 ">
                    <div className='flex items-center justify-between'>
                        <div className='bg-green-400 rounded-full p-4'>
                            <MdOutlineLandslide size={60} />
                        </div>
                        <div className='text-right flex flex-col gap-4'>
                            <h2 className="text-2xl font-semibold text-gray-700">
                                Total Lands
                            </h2>
                            <p className="text-3xl font-medium text-green-500">
                                {landCount}+
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-blue-200 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-6 py-8 ">
                    <div className='flex items-center justify-between'>
                        <div className='bg-blue-400 rounded-full p-4'>
                            <IoIosPeople size={60} />
                        </div>
                        <div className='text-right flex flex-col gap-4'>
                            <h2 className="text-2xl font-semibold text-gray-700">
                                Total Customers
                            </h2>
                            <p className="text-3xl font-medium text-blue-500">
                                {customerCount}+
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div className='bg-green-200 h-[500px] w-full border mt-8 rounded-xl relative shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
                <video className="absolute z-10 inset-0 w-full h-full object-cover rounded-xl" autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

        </div>
    );
}

export default Dashboard;
