import React, { useState } from 'react'
import UserHeader from '../../components/common/UserHeader'
import Lands from './Lands'
import Buildings from '../users/Buildings'

const Properties = () => {
    const [iseActive, setIsActive] = useState('Buildings')
   
    return (
        <>
            <UserHeader
                pageName="Properties"
                mainText="Home" />

            <div className='mx-10 mt-5 border  flex justify-around rounded-lg overflow-hidden'>
                <button
                    className={`font-bold w-full h-full py-2 ${iseActive === 'Buildings' ? "bg-blue-500 text-white transition duration-300 ease-in-out" : "bg-gray-200"}`}
                    onClick={() => setIsActive('Buildings')}>Buildings</button>
                <button
                    className={`font-bold w-full h-full py-2 ${iseActive === 'Lands' ? "bg-blue-500 text-white transition duration-300 ease-in-out" : "bg-gray-200"}`}
                    onClick={() => setIsActive('Lands')}>Lands</button>
            </div>
            {
                iseActive === "Buildings" &&
                <div className='px-10 py-5 tracking-wide'>
                    <Buildings />
                </div>
            }
            {
                iseActive === 'Lands' &&
                <div className='px-10 py-5 tracking-wide'>
                    <Lands />
                </div>
            }
        </>
    )
}

export default Properties