import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogForm from "../../components/Admin/Blog/BlogForm";
import BlogCard from "../../components/Admin/Blog/BlogCard";
import Pagination from "../../components/Admin/Blog/Pagination";
import BlogModal from "../../components/Admin/Blog/BlogModal";
import BlogViewModal from "../../components/Admin/Blog/BlogViewModal";
import { CircularProgress } from "@mui/material";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(12);
  const [loadingStates, setLoadingStates] = useState({});
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `https://assavio-realestate.onrender.com/real-estate/blogs/get-all-blogs`
      );
      console.log(response?.data?.blogs);
      setBlogs(response?.data?.blogs);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs?.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleAddBlog = async (newBlog) => {
    setFormLoading(true);
    try {
      const response = await axios.post(
        "https://assavio-realestate.onrender.com/real-estate/blogs/create-blogs",
        newBlog,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setBlogs([...blogs, response.data?.Blog]);
      setIsAddModalOpen(false);
    } catch (error) {
      console.error("Error adding blog:", error);
    } finally {
      setFormLoading(false);
    }
  };

  const handleEditBlog = async (updatedBlog) => {
    setFormLoading(true);
    try {
      const response = await axios.put(
        `https://assavio-realestate.onrender.com/real-estate/blogs/update-blogs/${updatedBlog._id}`,
        updatedBlog,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      await fetchBlogs();
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error editing blog:", error);
    } finally {
      setFormLoading(false);
    }
  };

  const handleDeleteBlog = async (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: { delete: true } }));
    try {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://assavio-realestate.onrender.com/real-estate/blogs/delete-blogs/${id}`,
        headers: {},
      };

      await axios.request(config);
      await fetchBlogs();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: { delete: false } }));
    }
  };

  const openEditModal = (blog) => {
    setSelectedBlog(blog);
    setIsEditModalOpen(true);
  };

  const openViewModal = (blog) => {
    setSelectedBlog(blog);
    setIsViewModalOpen(true);
  };

  return (
    <div className="container mx-auto px-10 py-5">
      <div className="flex justify-between mb-6">
        <h2 className="text-2xl font-normal">Blogs</h2>
        <button
          className="bg-primaryColor text-white px-4 py-2 rounded"
          onClick={() => setIsAddModalOpen(true)}
        >
          Add Blog
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentBlogs.map((blog) => (
          <BlogCard
            key={blog._id}
            blog={blog}
            onView={() => openViewModal(blog)}
            onEdit={() => openEditModal(blog)}  // Pass the entire blog object
            onDelete={() => handleDeleteBlog(blog._id)}
            isDeleteLoading={loadingStates[blog._id]?.delete}
          />
        ))}
      </div>

      <Pagination
        blogsPerPage={blogsPerPage}
        totalBlogs={blogs.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      {isAddModalOpen && (
        <BlogModal onClose={() => setIsAddModalOpen(false)}>
          <BlogForm onSubmit={handleAddBlog} loading={formLoading} />
        </BlogModal>
      )}

      {isEditModalOpen && selectedBlog && (
        <BlogModal onClose={() => setIsEditModalOpen(false)}>
          <BlogForm blog={selectedBlog} onSubmit={handleEditBlog} loading={formLoading} />
        </BlogModal>
      )}

      {isViewModalOpen && selectedBlog && (
        <BlogViewModal
          blog={selectedBlog}
          onClose={() => setIsViewModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Blogs;
