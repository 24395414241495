import React, { useEffect, useState } from "react";
import UserHeader from "../../components/common/UserHeader";
import { CiHome, CiLocationOn } from "react-icons/ci";
import care_icon from "../../assets/properties/care_icon.svg";
import image1 from "../../assets/properties/area_icon.svg";
import image2 from "../../assets/properties/unitIcon.svg";
import image3 from "../../assets/properties/towerIcon.svg";
import image4 from "../../assets/properties/ApprovalIcon.svg";
import image5 from "../../assets/properties/developerIcon.svg";
import image6 from "../../assets/properties/buildingsIcon.svg";
import propertyDetail_bg from "../../assets/properties/propertyDetail_bg.png";
import { IoCubeOutline } from "react-icons/io5";
import { MdOutlineBathtub } from "react-icons/md";
import {
  FaArrowRightLong,
  FaCar,
  FaHouseMedical,
  FaTowerObservation,
} from "react-icons/fa6";
import name_card_below_form from "../../assets/properties/name_card_below_form.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import InquiryForm from "../../components/contact/Inquiry";
import { LiaCertificateSolid } from "react-icons/lia";
import { capitalizeFirstLetter } from "../../components/common/FirstLetterCapitalize";
import "../users/Services/BuildingDetail.css";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

const BuildingDetails = () => {
  const params = useParams();
  console.log(params?.title);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Property Details");
  const [propertyDetail, setPropertyDetail] = useState({});
  const [selectedImage, setSeelectedImage] = useState("");
  const [relatedBuildings, setRelatedBuildings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(5);

  useEffect(() => {
    document.title = `Assavio | ${params?.title}`;
}, []);

  const GetBuildingData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://assavio-realestate.onrender.com/real-estate/buildings/get-building-by-id/${params?.id}`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data);
    setSeelectedImage(response?.data?.BuildingsImages[0]);

        setPropertyDetail(response?.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    getBuildingDatas();
    GetBuildingData();
  }, []);

  const data = [
    {
      image: image1,
      name: "Area",
      detail: propertyDetail?.physicalCharacteristics?.totalArea + " " + "acre",
    },
    {
      image: image2,
      name: "Unit",
      detail:
        propertyDetail?.units?.map((ele) => ele?.unitType + "," + " ") || "",
    },
    {
      image: image3,
      name: "Number Of Tower",
      detail: propertyDetail?.physicalCharacteristics?.numberOfTowers || 0,
    },
    {
      image: image4,
      name: "Approval",
      detail: propertyDetail?.generalInfo?.approval || "",
    },
    {
      image: image5,
      name: "Developer",
      detail:
        capitalizeFirstLetter(propertyDetail?.generalInfo?.developerName) || "",
    },
    {
      image: image6,
      name: "Building Type",
      detail:
        capitalizeFirstLetter(propertyDetail?.generalInfo?.BuildingsType) || "",
    },
  ];

  useEffect(() => {
    const updateVisibleCards = () => {
      if (window.innerWidth >= 1280) {
        setVisibleCards(5);
      } else if (window.innerWidth >= 1024) {
        setVisibleCards(4);
      } else if (window.innerWidth >= 768) {
        setVisibleCards(3);
      } else {
        setVisibleCards(1);
      }
    };

    window.addEventListener("resize", updateVisibleCards);
    updateVisibleCards();

    return () => window.removeEventListener("resize", updateVisibleCards);
  }, []);

  const handleNext = () => {
    if (currentIndex < propertyDetail?.BuildingsImages?.length - visibleCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getBuildingDatas = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/buildings/get-all-buildings`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data);
        const filterData = response?.data
          ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .slice(0, 3);
        setRelatedBuildings(filterData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  

  return (
    <div>
      <div>
        <div
          style={{
            backgroundImage: `url(${propertyDetail_bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="h-auto w-full"
        >
          <div className="lg:flex lg:flex-row items-center justify-between p-8 text-white bg-[#12121265]">
            <div className="lg:w-[70%]">
              <h1 className="text-2xl lg:w-[70%] leading-[50px]">
                {capitalizeFirstLetter(
                  propertyDetail?.generalInfo?.BuildingsName
                )}
              </h1>
            </div>
            <div className="lg:mt-0 mt-4">
              <a href="tel:+917608947733">
                <button className="border border-[#BCEDFD] px-10 py-3 rounded-md">
                  {capitalizeFirstLetter(
                    propertyDetail?.financialInfo?.pricingStatus
                  ) || "Price On Request"}
                </button>
              </a>
            </div>
          </div>
          <div
            id="coneShape"
            className="border-2 lg:h-[500px] md:h-[400px] h-[300px] m-auto w-[90%] my-4 overflow-hidden"
          >
            <img className="h-full w-full bg-black" src={selectedImage} alt="img" />
          </div>

          <div className="p-8 text-white bg-[#12121265] flex items-center justify-between gap-4">
            <div>
              <button
                onClick={handlePrev}
                disabled={currentIndex === 0}
                className={`${
                  currentIndex === 0 ? "cursor-not-allowed opacity-50" : ""
                }`}
              >
                <IoIosArrowDropleftCircle size={30} />
              </button>
            </div>
            <div className="flex gap-8 overflow-x-scroll">
              {propertyDetail?.BuildingsImages?.slice(
                currentIndex,
                currentIndex + visibleCards
              ).map((ele, index) => (
                <div
                  key={index}
                  className="w-[300px] h-[150px] border-2 border-white rounded-full overflow-hidden"
                >
                  <img
                    onClick={() => setSeelectedImage(ele)}
                    src={ele}
                    alt={`Building ${index}`}
                    className="w-full h-full object-cover cursor-pointer"
                  />
                </div>
              ))}
            </div>
            <div>
              <button
                onClick={handleNext}
                disabled={
                  currentIndex >= propertyDetail?.BuildingsImages?.length - 5
                }
                className={`${
                  currentIndex >= propertyDetail?.BuildingsImages?.length - 5
                    ? "cursor-not-allowed opacity-50"
                    : ""
                }`}
              >
                <IoIosArrowDroprightCircle size={30} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="px-10 py-10 tracking-wide">
        <div className="grid xl:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.map((ele) => (
            <div className="flex items-center gap-4 border bg-[#D8EAEB] py-4 px-2 rounded-xl">
              <div>
                <img src={ele?.image} alt="icons" className="w-8" />
              </div>
              <div className="flex flex-col gap-1">
                <h1 className="font-bold text-sm">{ele?.name}</h1>
                <p className="text-[12px] capitalize text-primaryColor">
                  {ele?.detail}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="lg:flex lg:flex-row gap-4 mt-8">
          <div className="h-full lg:w-[70%]">
            <div className="flex">
              <button
                className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${
                  activeTab === "Property Details" &&
                  "border-blue-500 text-blue-500"
                } focus:outline-none`}
                onClick={() => handleTabChange("Property Details")}
              >
                Property Details
              </button>
              <button
                className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${
                  activeTab === "Property Plan" &&
                  "border-blue-500 text-blue-500"
                } focus:outline-none`}
                onClick={() => handleTabChange("Property Plan")}
              >
                Property Plan
              </button>
              <button
                className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${
                  activeTab === "Locations" && "border-blue-500 text-blue-500"
                } focus:outline-none`}
                onClick={() => handleTabChange("Locations")}
              >
                Locations
              </button>
            </div>

            <div className="mt-4">
              {activeTab === "Property Details" && (
                <div className="text-secondaryTextColor tracking-wider">
                  {propertyDetail?.propertyDescription?.map((ele) => (
                    <p class="text-secondaryTextColor mb-2">{ele}</p>
                  ))}

                  <p className="text-blue-600 mt-4">Nearest Locations</p>
                  <div className="mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    {propertyDetail?.generalInfo?.locationBenifits?.map(
                      (ele) => (
                        <div className="flex items-center gap-4">
                          <p className="text-black">{ele?.name} :</p>
                          <p>{ele?.value}</p>
                        </div>
                      )
                    )}
                  </div>

                  <p className="text-blue-600 mt-4">Additional Information</p>
                  <div className="mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    <div className="flex items-center gap-4">
                      <p className="text-black">Year Of Prossesion :</p>
                      <p>
                        {
                          propertyDetail?.physicalCharacteristics
                            ?.yearOfProssesion
                        }
                        Year
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">Parking Facilities :</p>
                      <p>
                        {
                          propertyDetail?.physicalCharacteristics
                            ?.parkingFacilities
                        }
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">No Of Tower :</p>
                      <p>
                        {
                          propertyDetail?.physicalCharacteristics
                            ?.numberOfTowers
                        }
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">Total Floors :</p>
                      <p>
                        {propertyDetail?.physicalCharacteristics?.totalFloors}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">Maintenance Fees :</p>
                      <p>{propertyDetail?.financialInfo?.maintenanceFees}</p>
                    </div>
                  </div>

                  <p className="text-blue-600 mt-4">Aminities</p>
                  <div className="mt-4 grid lg:grid-cols-3 grid-cols-2 gap-4 text-black">
                    {propertyDetail?.extraInfo?.amenities?.map((ele) => (
                      <div className="flex items-center gap-4">
                        <p className="text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                          </svg>
                        </p>
                        <p>{ele}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {activeTab === "Property Plan" && (
                <div className="p-4">
                  <img
                    src={propertyDetail?.BuildingsPlansImage}
                    alt="map"
                    className="md:w-[80%] w-full h-auto m-auto"
                  />
                </div>
              )}
              {activeTab === "Locations" && (
                <div className="bg-gray-100 rounded-lg p-8 h-full flex items-center justify-center">
                  <div>
                    <div className="bg-violet-200 text-violet-500 hover:bg-violet-500 hover:text-white transition duration-200 ease-linear h-20 w-20 rounded-full flex items-center justify-center">
                      <CiLocationOn size={40} className="" />
                    </div>
                    <h1 className="text-2xl mt-4 font-bold text-secondaryTextColor">
                      Address
                    </h1>
                    <h1 className="flex flex-wrap text-xl mt-2 leading-[40px]">
                      <p>{propertyDetail?.locationDetails?.address},</p>
                      <p>{propertyDetail?.locationDetails?.city},</p>
                      <p>{propertyDetail?.locationDetails?.state},</p>
                      <p>{propertyDetail?.locationDetails?.country},</p>
                      <p>{propertyDetail?.locationDetails?.zipCode}</p>
                    </h1>
                  </div>
                </div>
              )}
              {/* {activeTab === 'Review' && (
                                    <div>Review content</div>
                                )} */}
            </div>
          </div>
          <div className="h-full lg:w-[30%] lg:mt-0 mt-8">
            <InquiryForm />

            <div className="mt-8 bg-black flex items-center gap-4 p-6 rounded-md">
              <img src={care_icon} alt="name_card_below_form" className="" />
              <div className="text-white flex flex-col gap-2 tracking-wider">
                <h1 className="font-bold text-2xl">Assavio</h1>
                <p className="text-2xl font-medium">+91 99373 45999</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between mt-8">
          <h1 className="md:text-4xl text-xl">Related Properties</h1>
          <button
            onClick={() => {
              navigate(`/properties`);
              window.scrollTo(0, 0);
            }}
            className=" flex items-center gap-2 text-primaryColor"
          >
            Explore <FaArrowRightLong size={25} />
          </button>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4">
          {relatedBuildings.map((ele) => (
            <div
              onClick={() => {
                navigate("/properties");
                window.scrollTo(0, 0);
              }}
              className="rounded-lg overflow-hidden shadow-[0_3px_10px_rgb(0,0,0,0.2)] cursor-pointer"
            >
              <div className="h-60 overflow-hidden">
                <img
                  src={ele?.BuildingsImages[0]}
                  alt="Building img"
                  className="h-full w-full object-cover hover:scale-125 transition duration-1000"
                />
              </div>

              <div className="p-4">
                <h1>
                  {capitalizeFirstLetter(ele?.generalInfo?.BuildingsName)}
                </h1>
                <div className="flex gap-2 mt-2 h-8">
                  <CiLocationOn
                    size={20}
                    className="text-primaryColor mt-[2px]"
                  />
                  <p className="text-secondaryTextColor text-sm lg:w-full w-[80%]">
                    {capitalizeFirstLetter(ele?.locationDetails?.address)},
                    {capitalizeFirstLetter(ele?.locationDetails?.city)},
                    {capitalizeFirstLetter(ele?.locationDetails?.state)},
                    {capitalizeFirstLetter(ele?.locationDetails?.country)},
                    {capitalizeFirstLetter(ele?.locationDetails?.zipCode)}
                  </p>
                </div>

                <div className="flex items-center gap-4 mt-6 mb-4">
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <FaTowerObservation className="text-primaryColor" />
                    <p>{ele?.physicalCharacteristics?.numberOfTowers || 0}</p>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <FaHouseMedical className="text-primaryColor" />
                    <p>{ele?.units?.length}</p>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <LiaCertificateSolid className="text-primaryColor" />
                    <p>{ele?.generalInfo?.approval}</p>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <IoCubeOutline className="text-primaryColor" />
                    <p>{ele?.physicalCharacteristics?.totalArea || 0} acre</p>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuildingDetails;
