import React from 'react'
import email_icon from '../../assets/home/email-icon.svg'
import phone_icon from '../../assets/home/phone-icon.svg'
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { PiWhatsappLogo } from "react-icons/pi";
import { Link } from 'react-router-dom';

const TopNavbar = () => {
    return (
        <div className='hidden md:block bg-[#062F23] text-white tracking-wider px-10 py-2'>
            <div className='flex items-center justify-between'>
                <div className='md:flex md:flex-row flex-col items-center gap-10'>
                    <div className='flex items-center gap-2'>
                        <img
                            src={email_icon}
                            alt="email_icon" />

                        <p className='text-sm'>
                            info@assavio.com
                        </p>
                    </div>

                    <div className='flex items-center gap-2'>
                        <img
                            src={phone_icon}
                            alt="email_icon" />
                        <p className='text-sm'>
                            +91 99373 45999 or +91 78550 88870
                        </p>
                    </div>

                    <div className='flex items-center gap-2'>
                        <PiWhatsappLogo size={26} color='' />
                        <p className='text-sm'>
                            +91 76089 47733
                        </p>
                    </div>
                </div>

                <div className='hidden md:flex items-center gap-3'>
                    <Link
                        to="https://www.facebook.com/assaviorealty"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaFacebookF size={20} className="cursor-pointer" />
                    </Link>

                    <Link
                        to="https://www.instagram.com/assaviorealty"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <RiInstagramFill size={20} className="cursor-pointer" />
                    </Link>

                    <Link
                        to="https://www.linkedin.com/company/assaviorealty"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaLinkedinIn size={20} className="cursor-pointer" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default TopNavbar