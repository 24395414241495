import React, { useEffect, useState } from "react";
import BlogCard from "../../components/Blog/BlogCard";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GetAllBlog();
  }, []);

  const GetAllBlog = () => {
    setIsLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://assavio-realestate.onrender.com/real-estate/blogs/get-all-blogs",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("data----->", response?.data?.blogs);
        setBlogs(response?.data?.blogs);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  function formatDate(isoString) {
    const date = new Date(isoString);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${daySuffix(day)} ${month} ${year}`;
  }

  const navigateToDetail = (post) => {
    const titleWithDashes = post.title.replace(/\s+/g, '-');
    navigate(`/blog/${encodeURIComponent(titleWithDashes)}`, { state: { post } });
  };

 

  return (
    <div className="lg:px-16 md:px-10 px-5 py-10">
      <div className="flex lg:flex-row flex-col gap-4">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {isLoading ? (
            // Show skeleton loaders while fetching data
            Array(6).fill().map((_, index) => (
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="animate-pulse">
                <div className="bg-gray-300 h-48 w-[420px]"></div>
                <div className="p-4">
                  <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                  <div className="h-4 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-3 bg-gray-300 rounded w-full mb-2"></div>
                  <div className="h-3 bg-gray-300 rounded w-5/6"></div>
                </div>
              </div>
            </div>
            ))
          ) : blogs.length > 0 ? (
            // Show blog cards if blogs are available
            blogs.map((post, index) => (
              <BlogCard key={index} post={post} />
            ))
          ) : (
            // Show message if no blogs are available
            <div className="col-span-full text-center text-gray-500 text-xl">
              No blogs available at the moment.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;