import React, { useEffect, useState } from "react";
import UserHeader from "../../components/common/UserHeader";
import { MdOutlineMail } from "react-icons/md";
import contact from "../../assets/contact.png";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { motion } from "framer-motion";
import GetInTouchForm from "../../components/contact/GetInTouchForm";
import { PiWhatsappLogo } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { TbPhoneCall } from "react-icons/tb";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { MdOutlineCorporateFare } from "react-icons/md";

const faqs = [
  {
    question: "What is real estate investment?",
    answer: "Real estate investment involves purchasing, owning, managing, renting, or selling properties for the purpose of generating income or profit.",
  },
  {
    question: "What types of real estate investments are available?",
    answer: "Common types of real estate investments include residential properties (such as single-family homes, condos, and apartments), commercial properties (such as office buildings, retail spaces, and warehouses), industrial properties, and vacant land.",
  },
  {
    question: "How do I get started with real estate investment?",
    answer: "To get started, you can educate yourself about the market, develop a clear investment strategy, conduct thorough research, build a network of professionals (such as real estate agents, lenders, and contractors), and consider working with experienced mentors or advisors.",
  },
  {
    question: "How much money do I need to invest in real estate?",
    answer: "The amount of money needed depends on various factors, including the type of property, its location, market conditions, financing options, and your investment goals. You can invest directly or indirectly through real estate investment trusts (REITs) or crowdfunding platforms, which may require lower initial investments.",
  },
  {
    question: "How can I finance a real estate investment?",
    answer: "Financing options include traditional mortgages, private loans, hard money loans, seller financing, crowdfunding, and partnerships. The choice of financing depends on factors such as your creditworthiness, the property's value, and your investment strategy.",
  },
  {
    question: "How can I maximize returns on my real estate investments?",
    answer: "Strategies for maximizing returns include conducting thorough due diligence, investing in high-demand areas, maintaining properties effectively, optimizing rental income, minimizing expenses, leveraging tax advantages, and staying informed about market trends.",
  },
];

const Contact = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const contactsData = [
    {
      Icon: TbPhoneCall,
      title: "Phone",
      info: "+91-9937345999"
    },
    {
      Icon: MdOutlineMail,
      title: "Email",
      info: "assavio2024@gmail.com"
    },
    {
      Icon: PiWhatsappLogo,
      title: "WhatsApp",
      info: "+91-9393991563"
    },
    // {
    //   Icon: HiOutlineBuildingOffice2,
    //   title: "Registered Office",
    //   info: "Plot No.- 16 & 15/2538, Khandagiri Vihar, Khandagiri, Bhubaneswar, Odisha"
    // },
    {
      Icon: MdOutlineCorporateFare,
      title: "Corporate Office",
      info: "HIG-1/5, SundarpadaHata Rd, Bhimatangi Housing Colony, Bhubaneswar, Odisha 751002"
    }
  ]

  return (
    <React.Fragment>
      <UserHeader
        pageName="Contact Us"
        mainText="Home" />

      <div className="px-10 mt-6">
        <div className="">
          <div className="flex justify-center items-center flex-wrap gap-6">
            {contactsData?.map(({ Icon, title, info }, index) => (
              <div
                key={index}
                className="w-full md:w-auto max-w-xl relative overflow-hidden bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="absolute inset-0 bg-gradient-to-tr from-red-500 to-red-300 opacity-20"></div>

                <div className="flex md:flex-row flex-col justify-center items-center p-5 w-full">
                  <div className="flex items-center justify-center p-3 w-16 min-w-16 h-16 bg-white rounded-full shadow-md">
                    <Icon size={24} className="text-red-500" />
                  </div>

                  <div className="ml-5 tracking-wider">
                    <h1 className="text-xl font-bold text-gray-800">
                      {title}
                    </h1>
                    <p className="text-gray-600 mt-1">
                      {info}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex lg:flex-row flex-col gap-4 mt-12">
          <div className="lg:w-1/2 w-full">
            <img src={contact} alt="contact" className="w-full" />
          </div>
          <div className="lg:w-1/2 w-full">
            <GetInTouchForm />
          </div>
        </div>

        <div className="flex justify-between  mt-12 gap-2" >
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-3">
              <p className="text-[#A24319] tracking-wider text-[20px]">
                FAQ's
              </p>
              <h1
                className="text-[#212121] font-semibold lg:text-[40px] text-2xl  w-full"
                style={{ lineHeight: "1.2" }}
              >
                All Your Concern
              </h1>
            </div>

            <div className="w-full mt-4 flex gap-3 flex-col">
              {faqs.map((faq, index) => (
                <motion.div
                  key={index}
                  className={`mb-4 ${activeIndex === index ? 'text-[#006529]' : 'bg-gray-100'}`}
                  layout
                  initial={{ borderRadius: 10 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <div
                    className={`flex justify-between items-center cursor-pointer border px-4 py-2 rounded-md w-full ${activeIndex === index ? 'text-[#006529]' : ''}`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <div className="font-semibold lg:text-[20px]">
                      {faq.question}
                    </div>
                    <div>
                      {activeIndex === index ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronDown />
                      )}
                    </div>
                  </div>
                  {activeIndex === index && (
                    <motion.div
                      className="px-4 py-2"
                      layout
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      transition={{ duration: 0.3 }}
                    >
                      {faq.answer}
                    </motion.div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
