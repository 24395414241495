import React from 'react'
import video from '../../assets/home/bg_video_home.mp4'

const VideoBanner = () => {
    return (
        <div className='bg-blue-900'>
            <div className='md:contents hidden'>
                <div className='bg-black xl:h-[400px] md:h-[300px] overflow-hidden'>
                    <video
                        className='w-full h-auto opacity-50'
                        src={video}
                        title=""
                        autoPlay
                        muted
                        loop
                    ></video>
                </div>
                <div className='absolute  xl:mt-[-250px] lg:mt-[-220px] md:mt-[-220px]'>
                    <h1
                        style={{ textShadow: '2px 5px 4px rgba(0, 0, 0, 0.5)' }}
                        className='text-5xl text-center text-white tracking-wide leading-[70px] w-[70%] m-auto'>
                        from Viewing to Moving, Comprehensive Real Estate Services Bhubaneswar
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default VideoBanner