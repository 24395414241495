import React, { useEffect } from 'react';
import experince_img from '../../assets/home/experience10Year.svg';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const HomeAboutUs = () => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

    const aboutData = [
        {
            "header": "Personalized Property Matching",
            "desc_1": "Our service matches clients with properties tailored to their life goals, using smart search tools that consider career opportunities, education, and community attributes.",
        },
        {
            "header": "Investment in Stability",
            "desc_1": "Buying a home is both a lifestyle choice and a long-term investment in financial stability. We offer tools and resources to help buyers understand market trends and the investment potential of properties.",
        },
        {
            "header": "Community Integration",
            "desc_1": "Our properties serve as gateways to vibrant communities, enriching lives with access to top schools, amenities, and unique cultural aspects. Testimonials from satisfied clients highlight the positive impacts.",
        },
        {
            "header": "Expert Guidance Through Complexity",
            "desc_1": "Real estate transactions can be complex and daunting. Highlight your expertise in navigating these waters easily with professional guidance at every step, ensuring a stress-free process that paves the way to a brighter future.",
        }
    ]

    return (
        <div className='px-10 py-10 lg:grid lg:grid-cols-2 flex flex-col h-auto tracking-wide lg:gap-0 gap-8'>
            <div className='mt-4'>
                <div>
                    <p className='text-primaryColor'>
                        About Us
                    </p>

                    <h1 className='md:text-3xl text-2xl w-[80%] mt-4 md:leading-[40px] font-extrabold'>
                        Unlocking Doors to Your Future
                    </h1>

                    {
                        aboutData?.map((data, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <h3 data-aos="zoom-in" className='text-lg w-[80%] mt-1 md:leading-[40px] font-bold text-primaryColor'>
                                        {index + 1}. {data.header}
                                    </h3>

                                    <p data-aos="zoom-out" className='text-secondaryTextColor text-justify'>
                                        {data.desc_1}
                                    </p>
                                </React.Fragment>
                            )
                        })
                    }

                    <div className='flex items-center md:justify-start justify-center'>
                        <button
                            onClick={() => {
                                navigate('/about');
                                window.scrollTo(0, 0);
                            }}
                            className='bg-primaryBtnColor hover:bg-primaryBtnHoverColor text-white px-8 py-3 rounded-md text-sm mt-4'>
                            Discover More
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-end'>
                <img data-aos="flip-up" src={experince_img} alt="House Image" className='lg:w-[90%]' />
            </div>
        </div>
    )
}

export default HomeAboutUs;