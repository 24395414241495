
import React, { useEffect, useState } from 'react'
import UserHeader from '../../components/common/UserHeader'
import { CiLocationOn } from 'react-icons/ci'
import image1 from '../../assets/properties/area.svg'
import image2 from '../../assets/properties/unit.svg'
import image3 from '../../assets/properties/home.svg'
import image4 from '../../assets/properties/bathroom.svg'
import image5 from '../../assets/properties/garage.svg'
import image6 from '../../assets/properties/kitchen.svg'
import { IoCubeOutline } from 'react-icons/io5'
import { MdOutlineBathtub } from 'react-icons/md'
import { FaArrowRightLong, FaCar } from 'react-icons/fa6'
import name_card_below_form from '../../assets/properties/name_card_below_form.png'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import InquiryForm from '../../components/contact/Inquiry'
import { capitalizeFirstLetter } from '../../components/common/FirstLetterCapitalize'




const LandsDetail = () => {
    const params = useParams()
    const navigate = useNavigate()
    console.log(params?.id)
    const [activeTab, setActiveTab] = useState('Property Details');
    const [landsDetail, setLandsDetail] = useState({})
    const [selectedImage, setSeelectedImage] = useState('')
    const [relatedLands, setRelatedLand] = useState([])

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    const getLandsDatas = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/lands/get-all-lands`,
            headers: {},
        };

        axios.request(config)
            .then((response) => {
                const filteredBuildings = response?.data?.sort((a, b) => {
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    return dateB - dateA;
                });
                setRelatedLand(filteredBuildings.slice(0, 3));


                const filter = response?.data?.find((ele) => ele?._id === params?.id)
                console.log("xata", filter);
                setLandsDetail(filter)
                setSeelectedImage(filter?.propertyImages[0])
            })
            .catch((error) => {
                console.log(error);
            });

    }

    useEffect(() => {
        getLandsDatas()
    }, [])

    const data = [
        {
            image: image1,
            name: "Area",
            detail: landsDetail?.physicalCharacteristics?.totalArea + " " + "m2"
        },
        {
            image: image2,
            name: "Type",
            detail: landsDetail?.physicalCharacteristics?.terrainType
        },
        {
            image: image2,
            name: "Water",
            detail: landsDetail?.utilities?.waterAccess
        },
        {
            image: image2,
            name: "Electricity",
            detail: landsDetail?.utilities?.electricityAccess
        },

    ];


    return (
        <div>
            <UserHeader pageName="Land Details" mainText="Land" />
            <div className='px-10 py-10 tracking-wide'>
                <div>
                    <div className='lg:flex lg:flex-row items-center justify-between'>
                        <div className='lg:w-[70%]'>
                            <h1 className='text-2xl lg:w-[70%] leading-[50px]'>{landsDetail?.generalInfo?.propertyName}</h1>
                            <div className='flex gap-2 mt-2'>
                                <CiLocationOn size={20} className='text-primaryColor mt-1' />
                                <p className='text-secondaryTextColor text-lg'>
                                    {landsDetail?.locationDetails?.address},
                                    {landsDetail?.locationDetails?.city},
                                    {landsDetail?.locationDetails?.state},
                                    {landsDetail?.locationDetails?.country},
                                    {landsDetail?.locationDetails?.zipCode}
                                </p>
                            </div>
                        </div>
                        <div className='lg:mt-0 mt-4 lg:w-[30%]'>
                            {/* <div className='bg-[#60D3EC] px-8 py-4 text-center rounded-md'>
                                <h1 className='text-3xl'>₹ {landsDetail?.financialInfo?.sellingPrice}</h1>
                                <h1 className='text-2xl line-through'>₹ 10,000,000</h1>
                            </div> */}
                        </div>
                    </div>

                    <div className='h-[500px] w-full mt-6 rounded-lg overflow-hidden'>
                        <img className='h-full w-full' src={selectedImage} alt="" />
                    </div>
                    <div className={`flex w-full overflow-x-scroll mt-2 gap-2`}>
                        {
                            landsDetail?.propertyImages?.map((ele) =>
                                <img onClick={() => setSeelectedImage(ele)} src={ele} alt="building images" className='w-full h-[200px] rounded-lg' />
                            )
                        }
                    </div>
                    <div className='mt-4 grid lg:grid-cols-4 grid-cols-2 gap-4'>
                        {
                            data?.map((ele) =>
                                <div className='bg:[#FFE9E9] hover:bg-primaryColor transition duration-300 ease-linear shadow-[0_3px_10px_rgb(0,0,0,0.2)] flex items-center justify-center text-center px-4 py-6 rounded-lg'>
                                    <div>
                                        <img src={ele?.image} alt="" className='w-[80px] h-[80px]' />
                                        <h1 className='mt-2 font-bold'>{ele.name}</h1>
                                        <p className='mt-2'>{ele?.detail}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className='lg:flex lg:flex-row gap-4 mt-8'>
                        <div className="h-full lg:w-[70%]">
                            <div className="flex">
                                <button
                                    className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${activeTab === 'Property Details' && 'border-blue-500 text-blue-500'} focus:outline-none`}
                                    onClick={() => handleTabChange('Property Details')}
                                >
                                    Property Details
                                </button>
                                <button
                                    className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${activeTab === 'Property Plan' && 'border-blue-500 text-blue-500'} focus:outline-none`}
                                    onClick={() => handleTabChange('Property Plan')}
                                >
                                    Property Plan
                                </button>
                                <button
                                    className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${activeTab === 'Locations' && 'border-blue-500 text-blue-500'} focus:outline-none`}
                                    onClick={() => handleTabChange('Locations')}
                                >
                                    Locations
                                </button>
                            </div>

                            <div className="mt-4">
                                {activeTab === 'Property Details' && (
                                    <div className="text-secondaryTextColor tracking-wider">
                                        <p class='text-secondaryTextColor'>
                                            Lorem ipsum dolor sit amet, nibh saperet te pri, at nam diceret disputationi. Quo an consul
                                            impedit, usu possim evertitur dissentiet ei, ridens minimum nominavi et vix. An per mutat
                                            adipisci. Ut pericula dissentias sed, est ea modus gloriatur
                                        </p>
                                        <p class='text-secondaryTextColor mt-4'>
                                            Nec in rebum primis causae. Affert iisque ex pri, vis utinam vivendo definitionem ad, nostrum
                                            omnesque per et. Omnium antiopam cotidieque cu sit. Id pri placerat voluptatum, vero dicunt
                                            dissentiunt eum et, adhuc iisque vis no. Eu suavitate contentiones definitionem mel, ex vide
                                            insolens ocurreret eam. Et dico blandit mea. Sea tollit vidisse mandamus te, qui movet efficiendi
                                            ex.
                                        </p>

                                        <p class='text-secondaryTextColor mt-4'>
                                            Pri tempor appareat no, eruditi repudiandae vix at. Eos at brute omnesque voluptaria, facer
                                            putent intellegam eu pri. Mei debitis ullamcorper eu, at quo idque mundi. Vis in suas porro
                                            consequat, nec ad dolor adversarium, ut praesent cotidieque sit. Veniam civibus omittantur duo
                                            ut, te his alterum complectitur. Mea omnis oratio impedit ne.
                                        </p>
                                    </div>
                                )}
                                {activeTab === 'Property Plan' && (
                                    <div className='p-4'>
                                        <img src="https://quomodothemes.website/homeco/uploads/custom-images/Property-plan-2023-05-08-10-14-55-6080.webp" alt="map" className='w-[80%] h-auto m-auto' />
                                    </div>
                                )}
                                {/* {activeTab === 'Booking' && (
                                    <div>Booking content</div>
                                )}
                                {activeTab === 'Video' && (
                                    <div>Video content</div>
                                )} */}
                                {activeTab === 'Locations' && (
                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-4 items-center'>
                                        <div className='bg-gray-100 rounded-lg p-8 h-full flex items-center justify-center'>
                                            <div>
                                                <div className='bg-violet-200 text-violet-500 hover:bg-violet-500 hover:text-white transition duration-200 ease-linear h-20 w-20 rounded-full flex items-center justify-center'>
                                                    <CiLocationOn size={40} className='' />
                                                </div>
                                                <h1 className='text-2xl mt-4 font-bold text-secondaryTextColor'>Address</h1>
                                                <h1 className='lg:w-[80%] text-xl mt-2 leading-[40px]'>
                                                    {capitalizeFirstLetter(landsDetail?.locationDetails?.address)},
                                                    {capitalizeFirstLetter(landsDetail?.locationDetails?.city)},
                                                    {capitalizeFirstLetter(landsDetail?.locationDetails?.state)},
                                                    {capitalizeFirstLetter(landsDetail?.locationDetails?.country)},
                                                    {capitalizeFirstLetter(landsDetail?.locationDetails?.zipCode)}
                                                </h1>
                                            </div>
                                        </div>
                                        <div className='bg-gray-100 rounded-lg p-8 tracking-wide text-lg text-secondaryTextColor h-full'>
                                            <p>Appetere fabellas ius te. Nonumes splendide deseruisse ea vis, alii velit vel eu. Eos ut scaevola platonem rationibus. Vis natum vivendo sententiae in, ea aperiam apeirian pri, in partem eleifend quo. Pro ex nobis utinam, nam et vidit numquam fastidii, ne per munere adolescens.</p>
                                        </div>
                                    </div>
                                )}
                                {/* {activeTab === 'Review' && (
                                    <div>Review content</div>
                                )} */}
                            </div>
                        </div>
                        <div className='h-full lg:w-[30%] lg:mt-0 mt-8'>
                            <InquiryForm />

                            <div className='mt-8'>
                                <img src={name_card_below_form} alt="name_card_below_form" className='w-full' />
                            </div>

                        </div>
                    </div>

                    <div className='flex items-center justify-between mt-8'>
                        <h1 className='md:text-4xl text-xl'>Related Properties</h1>
                        <button onClick={() => {
                            navigate(`/properties/`);
                            window.scrollTo(0, 0);
                        }}
                            className=' flex items-center gap-2 text-primaryColor'>Explore <FaArrowRightLong size={25} /></button>
                    </div>

                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4'>
                        {
                            relatedLands.map((ele) => (
                                <div
                                    onClick={() => {
                                        navigate(`/lands/${ele?._id}`);
                                        window.scrollTo(0, 0);
                                    }}
                                    className='rounded-lg overflow-hidden shadow-[0_3px_10px_rgb(0,0,0,0.2)]'>
                                    <div className='h-60 overflow-hidden'>
                                        <img src={ele?.propertyImages[0]} alt="Building img" className='h-full w-full object-cover hover:scale-125 transition duration-1000' />
                                    </div>
                                    {/* <img src={ele?.propertyImages[0]} alt="Property image" className='h-[300px] w-full' /> */}
                                    <div className='p-4'>
                                        <h1>{ele?.generalInfo?.propertyName}</h1>
                                        <div className='flex gap-2 mt-2 h-14'>
                                            <CiLocationOn size={20} className='text-primaryColor mt-[2px]' />
                                            <p className='text-secondaryTextColor text-sm'>
                                                {ele?.locationDetails?.address} ,
                                                {ele?.locationDetails?.city},
                                                {ele?.locationDetails?.state},
                                                {ele?.locationDetails?.country},
                                                {ele?.locationDetails?.zipCode}
                                            </p>
                                        </div>
                                        <hr />
                                        <div className='flex items-center justify-between mt-4'>
                                            <button onClick={() => {
                                                navigate(`/lands/${ele?._id}`);
                                                window.scrollTo(0, 0);
                                            }}
                                                className=' flex items-center gap-2 bg-primaryColor text-white hover:bg-primaryBtnHoverColor px-4 py-1 rounded-md'>Details</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
        </div >
    )
}

export default LandsDetail;
