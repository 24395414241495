import React from 'react';
import { FaCalendar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const BlogCard = ({ post }) => {
  const navigate = useNavigate();

  const navigateToDetail = (post) => {
    const titleWithDashes = post.title.replace(/\s+/g, '-'); // Replace spaces with dashes
    navigate(`/${post?._id}/blog/${encodeURIComponent(titleWithDashes)}`);
  };

  return (
    <div className="border p-4 rounded flex flex-col justify-between gap-4 shadow-lg">
      <div className="h-[250px]">
        <img
          src={post.blogImage}
          alt="blog image"
          className="w-full h-full object-cover"
        />
      </div>
      <h1 className="text-secondaryTextColor">{post.category}</h1>
      <h1 onClick={() => navigateToDetail(post)} className="text-2xl font-medium cursor-pointer">
        {post.title}
      </h1>
      <div className="flex gap-4">
        <div className="w-14 rounded-full overflow-hidden">
          <img
            src={post.authorImage}
            alt="Author Image"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="">{post.authorName}</h1>
          <div className="flex items-center gap-2">
            <FaCalendar color="brown" />
            <p className="text-secondaryTextColor">{new Date(post.createdDate).toLocaleDateString()}</p>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm text-secondaryTextColor">{post.paragraphs[0]}</p>
      </div>
      <button
        onClick={() => navigateToDetail(post)}
        className="border w-[100px] py-2 text-primaryColor border-primaryColor rounded hover:text-white hover:bg-primaryColor"
      >
        Read More
      </button>
    </div>
  );
};

export default BlogCard;
