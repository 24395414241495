import React, { useRef, useState } from 'react'
import emailjs from "emailjs-com";

const GetInTouchForm = () => {
  const initialState = {
    name: "",
    email: "",
    phone: "",
    message: "",
  }
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [changedMsg, setChangedMsg] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};


    if (!formData?.name) {
      isValid = false;
      errors.name = 'Name  is required';
    }

    if (!formData?.phone) {
      isValid = false;
      errors.phoneNo = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData?.phone)) {
      isValid = false;
      errors.phoneNo = 'Invalid phone number, must be 10 digits';
    }

    if (!formData?.email) {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(formData?.email)) {
      isValid = false;
      errors.email = 'Invalid email format';
    }
    setErrors(errors);
    return isValid;
  };

  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    {
      emailjs
        .sendForm("service_nfyknat", "template_kc4b4qx", form.current, "lUeNyF9ZL2EbTgojp")
        .then(
          (result) => {
            console.log(result.text);
            setFormData(initialState)
            setLoading(false)
            setChangedMsg("Your Details Has Been Received. We Will Contact You Soon!");
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
            setChangedMsg("Something Went Wrong!.Please Try Again");
          }
        );
    }

  };
  return (
    <form
      ref={form}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-3">
        <h2 className="lg:text-[30px] text-2xl text-black font-semibold">
          Get In Touch
        </h2>
        <p className="text-[20px] text-[#808080]">
          Let's ask your questions
        </p>
      </div>

      <div className="flex flex-col gap-3 mt-4">
        <input
          type="text"
          name="name"
          placeholder="Enter Your Name"
          value={formData?.name}
          onChange={handleChange}
          className="p-4 text-[#808080] placeholder-[#808080] bg-[#d2cef9]  w-full rounded-xl"
        />
        {errors.name && <div className="text-red-500 text-sm tracking-wider">
          {errors.name}
        </div>}

        <input
          type="text"
          name="email"
          placeholder="Enter Your Email"
          value={formData?.email}
          onChange={handleChange}
          className="p-4 text-[#808080] placeholder-[#808080] bg-[#d2cef9]  w-full rounded-xl"
        />
        {errors.email && <div className="text-red-500 text-sm tracking-wider">
          {errors.email}
        </div>}

        <input
          type="text"
          name="phone"
          placeholder="Enter Your Phone No."
          value={formData?.phone}
          onChange={handleChange}
          className="p-4 text-[#808080] placeholder-[#808080] bg-[#d2cef9] w-full rounded-xl"
        />
        {errors.phoneNo && <div className="text-red-500 text-sm tracking-wider">
          {errors.phoneNo}
        </div>}

        <textarea
          name="message"
          placeholder="message"
          value={formData?.message}
          onChange={handleChange}
          rows="4"
          className="p-4 text-[#808080] placeholder-[#808080] bg-[#d2cef9]   w-full rounded-xl"
        />
      </div>

      {changedMsg && <div className="mb-4 text-center text-red-500 mt-5 text-sm tracking-wider">
        {changedMsg}
      </div>}

      <div className="mt-4 flex justify-center items-center">
        <button
          disabled={loading}
          className="bg-[#A24319] hover:bg-primaryBtnHoverColor text-white rounded-md p-4 w-1/2">
          {loading ? "Please Wait.." : "Submit"}
        </button>
      </div>
    </form>
  )
}

export default GetInTouchForm