import React, { useEffect, useRef, useState } from 'react';
// import Logo from '../../assets/Logo.svg';
import Logo from '../../assets/Approvelogo1.png';

import { Link, useNavigate } from 'react-router-dom';
import { RiArrowDownSFill } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";

const BottomNavbar = () => {
    const [activeMenuItem, setActiveMenuItem] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    // console.log(activeMenuItem)
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
                setActiveMenuItem(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [activeMenuItem]);

    const toggleDropdownOptions = (menuItem) => {
        setActiveMenuItem(activeMenuItem === menuItem ? null : menuItem);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleItemClick = (path) => {
        navigate(path);
        // console.log(path);
        setMenuOpen(false); // Close the menu after item click
    };

    const menuItems = [
        {
            title: 'Home',
            path: '/'
        },
        {
            title: 'About US',
            path: '/about'
        },
        {
            title: 'Services',
            options: [
                { title: 'Discover Verified Properties', path: '/discover-properties' },
                { title: 'Property Selling Service', path: '/selling-service' },
                { title: 'Investment Services', path: '/investment-services' },
                { title: 'Post-Sale Support Services', path: '/plot-sale-support' }
            ]
        },
        {
            title: 'Properties',
            path: '/properties',
            // options: [
            //     { title: 'Ongoing', path: '/properties' },
            //     { title: 'Upcoming', path: '/upcoming-projects' }
            // ]
        },
        {
            title: 'Happy Clients',
            options: [
                { title: 'Testimonials', path: '/testimonials' },
                { title: 'Communities', path: '/communities' },
                { title: 'Gallery', path: '/gallery' }
            ]
        },
        {
            title: 'Career',
            path: '/career'
        },
        {
            title: 'Blogs',
            path: '/all-blogs'
        },
        {
            title: 'Contacts',
            path: '/contact'
        }
    ];


    return (
        <div>
            <div className='bg-white px-10 py-3 xl:flex hidden items-center justify-between shadow-md'>
                <div>
                    <img
                        onClick={() => navigate('/')}
                        src={Logo}
                        alt="Logo"
                        className='w-48 h-auto cursor-pointer' />
                </div>
                <div className='flex gap-8 items-center'>
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            className={`flex items-center gap-1 cursor-pointer relative ${activeMenuItem === item.title ? 'text-primaryBtnColor' : ''}`}
                            onClick={() =>
                                item.options ? toggleDropdownOptions(item.title) : handleItemClick(item.path)
                            }
                        >
                            {item.title}
                            {item.options && <IoIosArrowDown size={20} className='mt-1' />}
                            <AnimatePresence>
                                {activeMenuItem === item.title && item.options && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        ref={menuRef}
                                        className="absolute bg-white text-black z-40 top-[40px] left-0 shadow-lg rounded-b-xl overflow-hidden w-[200px]"
                                    >
                                        {item.options.map((option, index) => (
                                            <motion.div
                                                key={index}
                                                className="px-8 py-2 cursor-pointer hover:bg-primaryBtnColor hover:text-white"
                                                onClick={() => handleItemClick(option.path)}
                                            >
                                                {option.title}
                                            </motion.div>
                                        ))}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    ))}
                    <button
                        onClick={() => {
                            navigate('/login');
                            window.scrollTo(0, 0);
                        }}
                        className='bg-primaryBtnColor hover:bg-primaryBtnHoverColor text-white px-8 py-2 rounded-md text-sm'>
                        Login
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className='bg-white px-10 py-3 xl:hidden flex items-center justify-between shadow-lg'>
                <div>
                    <img src={Logo} alt="logo" className=' w-32 h-auto' />
                </div>
                <div onClick={toggleMenu}>
                    <GiHamburgerMenu size={30} />
                </div>
            </div>
            <AnimatePresence>
                {menuOpen && (
                    <div className='fixed inset-0 bg-transparent flex justify-end z-50'>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.9, x: 100 }}
                            animate={{ opacity: 1, scale: 1, x: 0 }}
                            exit={{ opacity: 0, scale: 0.9, x: 100 }}
                            transition={{ duration: 0.2 }}
                            ref={menuRef}
                            className='bg-white shadow-lg text-black p-5 w-[60%] flex flex-col justify-between'
                        >
                            <div>
                                {menuItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className='flex flex-col items-start gap-1 relative'
                                        onClick={() => (item.options ? toggleDropdownOptions(item.title) : handleItemClick(item.path))}
                                    >
                                        <div className='flex items-center mb-3 cursor-pointer text-[20px]'>
                                            {item.title}
                                            {item.options && <RiArrowDownSFill size={20} />}
                                        </div>
                                        {activeMenuItem === item.title && item.options && (
                                            <motion.div
                                                initial={{ opacity: 0, scale: 0.9, y: -10 }}
                                                animate={{ opacity: 1, scale: 1, y: 0 }}
                                                exit={{ opacity: 0, scale: 0.9, y: -10 }}
                                                transition={{ duration: 0.2 }} // Adjust the duration as needed for smoother animation
                                                className='bg-gray-100 text-black shadow-lg overflow-hidden w-full mb-4'
                                                style={{ zIndex: 9999, position: 'relative' }} // Ensure it's above other content
                                            >
                                                {item.options.map((option, index) => (
                                                    <motion.div
                                                        key={index}
                                                        whileHover={{ backgroundColor: '#FF1616', color: 'white' }}
                                                        className='px-8 py-2 text-[18px] transition duration-200 ease-linear cursor-pointer'
                                                        onClick={() => handleItemClick(option.path)}
                                                    >
                                                        {option.title}
                                                    </motion.div>
                                                ))}
                                            </motion.div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className='flex justify-between items-center'>
                                <button
                                    className='bg-primaryColor text-white px-4 py-2 rounded-md'
                                    onClick={() => navigate('/login')}
                                >
                                    Login
                                </button>

                                <button
                                    className='bg-gray-400 text-white px-4 py-2 rounded-md'
                                    onClick={() => setMenuOpen(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default BottomNavbar;
