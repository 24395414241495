import React from "react";
import "./Banner.css";
import { FaArrowRight } from "react-icons/fa6";
import realstate_line_image from "../../assets/home/realstate_line_image.png";
import L_shape from "../../assets/home/L_shape.png";
import banner_bg_image from "../../assets/home/banner_bg_image.svg";
import Slider from "./Slider";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBot,
  motionVariantsLeft,
  motionVariantsRight,
  motionVariantsTop,
} from "../common/MotionVarients";
import CountUp from 'react-countup';
import ChangingHeader from "./ChangingHeader";

const Banner = () => {
  
  return (
    <div className="h-auto border-b overflow-hidden">
      <div className="grid lg:grid-cols-2 grid-cols-1 h-full lg:gap-0 gap-8">
        <div className=" h-full w-full flex items-center justify-start p-8 pb-0">
          <div className="flex flex-col gap-4 ">
            <motion.h1
              variants={motionVariantsLeft}
              {...motionProperties}
              className="font-dancing text-red-700 xl:text-2xl lg:text-lg md:text-2xl"
            >
              Your Trusted Partner in Bhubaneswar Real Estate
            </motion.h1>

            <ChangingHeader />

            {/* <motion.h1
              variants={motionVariantsBot}
              {...motionProperties}
              className="font-bold font-josefin tracking-wide xl:text-6xl lg:text-4xl md:text-5xl text-3xl xl:leading-[70px] md:leading-[70px] leading-[40px]"
            >
              From Listing to <br /> Keys, We have Got <br /> You covered
            </motion.h1> */}

            <motion.div
              variants={motionVariantsRight}
              {...motionProperties}
              className="flex items-center gap-4"
            >
              <div className="bg-red-700 text-white md:p-4 p-1 rounded-full">
                <FaArrowRight size={30} />
              </div>
              <h1 className="text-2xl font-medium tracking-wide">
                Get Started
              </h1>
            </motion.div>
            <div className="md:flex gap-2 grid grid-cols-2">
              <motion.div
                variants={motionVariantsBot}
                {...motionProperties}
                className="md:w-[40%]"
              >
                <img src={realstate_line_image} alt="Banner Image" />
              </motion.div>
              <motion.div
                variants={motionVariantsTop}
                {...motionProperties}
                className="md:w-[20%] flex items-center md:justify-normal justify-center "
              >
                <h1 className="font-bold xl:text-2xl text-xl">
                  <CountUp end={3000} duration={5}/>+ <br />{" "}
                  <span className="font-normal text-secondaryTextColor">
                    Members
                  </span>
                </h1>
              </motion.div>
              <motion.div
                variants={motionVariantsBot}
                {...motionProperties}
                className="md:w-[20%] flex items-center md:justify-normal justify-center "
              >
                <h1 className="font-bold xl:text-2xl text-xl">
                <CountUp end={9000} duration={5}/>+ <br />{" "}
                  <span className="font-normal text-secondaryTextColor">
                    Agents
                  </span>
                </h1>
              </motion.div>
              <motion.div
                variants={motionVariantsRight}
                {...motionProperties}
                className="md:w-[16%] flex items-center md:justify-normal justify-center"
              >
                <img
                  src={L_shape}
                  alt="L shape image"
                  className="md:w-full w-[70%]"
                />
              </motion.div>
            </div>
          </div>
        </div>
        <div
          className="h-full w-full flex items-center xl:py-0 py-4 lg:pl-0 md:pl-5 "
          style={{
            backgroundImage: `url(${banner_bg_image})`,
            backgroundSize: "cover",
            backgroundPosition: "left",
          }}
        >
          <div className="w-full md:pl-0 pl-2">
            <motion.div
             variants={motionVariantsBot}
             {...motionProperties}
            class="flex gap-2 justify-start pl-20 items-center  mb-4">
              <div class="h-5 w-5 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
              <div class="h-5 w-5 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
              <div class="h-5 w-5 bg-black rounded-full animate-bounce"></div>
            </motion.div>
            <motion.div
              variants={motionVariantsRight}
              {...motionProperties}
              id="coneShapeDiv"
              className="md:h-[400px] h-[250px] w-[90%] overflow-hidden "
            >
              <Slider />
            </motion.div>

            <motion.div
             variants={motionVariantsTop}
             {...motionProperties}
            class="flex gap-2 justify-end items-center w-[90%] mt-4">
              <div class="h-4 w-4 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
              <div class="h-6 w-6 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
              <div class="h-4 w-4 bg-white rounded-full animate-bounce"></div>
              <div class="h-6 w-6 bg-white rounded-full animate-bounce"></div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
