import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../components/common/FirstLetterCapitalize';
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineDeleteSweep } from "react-icons/md";

const BuildingListTable = () => {
    const [buildingsData, setBuildingsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigate = useNavigate()

    const getBuildingsData = () => {
        setLoading(true);

        axios.get(`${process.env.REACT_APP_API_URL}/buildings/get-all-buildings`)
            .then((response) => {
                setBuildingsData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getBuildingsData();
    }, []);

    useEffect(() => {
        setFiltersApplied(searchTerm !== '' || filterType !== '' || minPrice !== '' || maxPrice !== '');
    }, [searchTerm, filterType, minPrice, maxPrice]);

    const filterBuildings = (data) => {
        return data.filter(building => {
            return (
                building.locationDetails.city.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (filterType === '' || building.generalInfo.BuildingsType.toLowerCase() === filterType.toLowerCase()) &&
                (minPrice === '' || building.financialInfo.sellingPrice >= parseInt(minPrice)) &&
                (maxPrice === '' || building.financialInfo.sellingPrice <= parseInt(maxPrice))
            );
        });
    };

    const buildingsPerPage = 5;
    const filteredBuildings = filterBuildings(buildingsData);
    const totalPages = Math.ceil(filteredBuildings.length / buildingsPerPage);

    const indexOfLastBuilding = currentPage * buildingsPerPage;
    const indexOfFirstBuilding = indexOfLastBuilding - buildingsPerPage;
    const currentBuildings = filteredBuildings.slice(indexOfFirstBuilding, indexOfLastBuilding);

    const handlePrevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterType = (e) => {
        setFilterType(e.target.value);
    };

    const handleMinPriceChange = (e) => {
        setMinPrice(e.target.value);
    };

    const handleMaxPriceChange = (e) => {
        setMaxPrice(e.target.value);
    };

    const clearFilters = () => {
        setSearchTerm('');
        setFilterType('');
        setMinPrice('');
        setMaxPrice('');
    };

    const deleteBuildings = (id) => {
        setDeleteLoading(true)
        let data = '';

        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/buildings/delete-building-by-id/${id}`,
            headers: {},
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(response?.data);
                getBuildingsData()
                setDeleteLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setDeleteLoading(false)
            });

    }

    return (
        <div className="container mx-auto">
            {deleteLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
            )}
            <h2 className="text-2xl font-normal ml-6 mt-4 mb-4">Building Lists</h2>

            <div className='px-6 py-2 w-full'>
                <input type="text" placeholder="Search by District....."
                    value={searchTerm} onChange={handleSearch}
                    className="px-4 py-2 shadow-lg border rounded outline-none bg-white w-full" />
            </div>
            <div className='w-full px-6 py-2'>
                <select value={filterType} onChange={handleFilterType} className="px-4 py-2 shadow-lg border rounded outline-none bg-white w-full">
                    <option value="">Filter by type</option>
                    <option value="residential">Residential</option>
                    <option value="commercial">Commercial</option>
                    <option value="both">Both</option>
                </select>
            </div>
            <div className="flex lg:flex-row flex-col gap-2 items-center  justify-between mb-4 px-6 mt-2">
                <div className='lg:flex grid grid-cols-1 items-center gap-1 lg:w-[70%] w-full'>
                    <input type="number" placeholder="Min Price" value={minPrice} onChange={handleMinPriceChange} className="px-4 py-2 shadow-lg border rounded outline-none bg-white" />
                    <input type="number" placeholder="Max Price" value={maxPrice} onChange={handleMaxPriceChange} className="px-4 py-2 shadow-lg border rounded outline-none bg-white" />
                </div>
                <button onClick={clearFilters} className={`px-4 py-2 rounded-md ml-4 ${filtersApplied ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800 cursor-not-allowed'}`} disabled={!filtersApplied}>
                    Clear Filters
                </button>
            </div>

            {loading ? (
                <div className="text-center">Loading...</div>
            ) : (
                <>
                    <div className='px-6'>
                        <div className="overflow-x-auto border rounded-t-lg">
                            <table className="min-w-full divide-y divide-gray-200 ">
                                {/* Table Header */}
                                <thead className="bg-blue-200">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">S no.</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Type</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Address</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Total Floors</th>
                                        {/* <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Selling Price</th> */}
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Amenities</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Action</th>
                                    </tr>
                                </thead>
                                {/* Table Body */}
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {currentBuildings.length === 0 ? (
                                        <tr>
                                            <td colSpan="8" className="px-6 py-4 whitespace-nowrap text-center">No data found.</td>
                                        </tr>
                                    ) : (
                                        currentBuildings.map((building, ind) => (
                                            <tr key={building._id}>
                                                <td className="px-6 py-4 whitespace-nowrap">{indexOfFirstBuilding + ind + 1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(building.generalInfo.BuildingsName)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(building.generalInfo.BuildingsType)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(building.generalInfo.currentStatus)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{`${capitalizeFirstLetter(building.locationDetails.address)}, ${capitalizeFirstLetter(building.locationDetails.city)}, ${capitalizeFirstLetter(building.locationDetails.country)}`}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(building.physicalCharacteristics.totalFloors)}</td>
                                                {/* <td className="px-6 py-4 whitespace-nowrap">${building.financialInfo.sellingPrice}</td> */}
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <ul>
                                                        {building.extraInfo.amenities.map((amenity, index) => (
                                                            <li key={index}>{capitalizeFirstLetter(amenity)}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td className='flex flex-col items-center justify-around gap-2'>
                                                    <button onClick={() => navigate(`/update-building/${building?._id}`)} className="px-6 py-4 whitespace-nowrap text-red-500 cursor-pointer"><FaRegEdit size={23} /></button>
                                                    <button onClick={() => deleteBuildings(building?._id)} className="px-6 py-4 whitespace-nowrap text-red-500 cursor-pointer"><MdOutlineDeleteSweep size={30} /></button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {/* Pagination */}
                        <div className='flex items-center justify-between p-4 border mt-4 rounded-b-lg mb-10'>
                            <div className='flex items-center gap-4'>
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1 || currentBuildings.length === 0}
                                    className={` ${currentPage === 1 || currentBuildings.length === 0 ? 'text-gray-300  cursor-not-allowed' : 'text-blue-500 hover:text-blue-600'}`}
                                >
                                    Prev
                                </button>
                                <h1>{currentPage}</h1>
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages || currentBuildings.length === 0}
                                    className={`${currentPage === totalPages || currentBuildings.length === 0 ? 'text-gray-300 cursor-not-allowed' : 'text-blue-500  hover:text-blue-600'}`}
                                >
                                    Next
                                </button>
                            </div>
                            <p className="text-sm text-gray-500">Page {currentPage} of {totalPages}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BuildingListTable;
