import React from "react";
import logo from "../../assets/Approvelogo1.png";
import bgImg from "../../assets/footerBg.png";
import { useNavigate } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { IoLocation } from "react-icons/io5";
import { BiSolidPhoneCall } from "react-icons/bi";

const UserFooter = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <footer
        className="bg-cover bg-center py-8 bg-[#abf0f9] shadow-xl border-t border-gray-300"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-6 text-white">
          <div className="space-y-3">
            <img src={logo} alt="Logo" className="h-auto w-60 mb-3" />

            <p className="font-medium text-sm md:text-base tracking-wider md:text-start  text-primaryTextColor">
              Assavio offers a seamless avenue for establishing a cutting-edge
              investment platform without necessitating coding expertise within
              a short time.
            </p>
          </div>

          <div className="flex md:justify-center md:text-start  w-full tracking-wider text-primaryTextColor">
            <div className="space-y-3">
              <h5 className="text-lg font-bold mb-1">
                <span className="pb-1 border-b-2 border-primaryColor">
                  Quick Links
                </span>
              </h5>
              <div className="space-y-2">
                <div
                  onClick={() => handleNavigation("/")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  Home
                </div>

                <div
                  onClick={() => handleNavigation("/properties")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  Properties
                </div>

                <div
                  onClick={() => handleNavigation("/about")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  About Us
                </div>

                <div
                  onClick={() => handleNavigation("/contact")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  Contact Us
                </div>
                <div
                  onClick={() => handleNavigation("/faqs")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  FAQ's
                </div>
                <div
                  onClick={() => handleNavigation("/term-and-conditions")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  Terms & Conditions
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-3 tracking-wider md:text-start  text-primaryTextColor">
            <h5 className="text-lg font-bold mb-1">
              <span className="pb-1 border-b-2 border-primaryColor">
                Contact Us
              </span>
            </h5>

            <div className="space-y-2">
              <div className="flex ">
                <BiSolidPhoneCall color="#B52E2B" size={25} />
                <div className="ml-2 flex flex-wrap">
                  <p>+91 76089 47733 ,</p>
                  <p>+91 99373 45999</p>
                </div>
              </div>

              <div className="flex items-center ">
                <RiWhatsappFill color="#B52E2B" size={20} />{" "}
                <span className="ml-2">+91-9393991563</span>
              </div>

              <div className="flex items-center ">
                <IoMdMail color="#B52E2B" size={20} />{" "}
                <span className="ml-2">info@assavio.com</span>
              </div>

              <div className="flex">
                <IoLocation color="#B52E2B" size={46} className="mt-[-10px]" />
                <span className="ml-2">
                  HIG-1/5, Sundarpada Hata Rd, Bhimatangi Housing Colony,
                  Bhubaneswar, Odisha 751002
                  {/* Plot No.- 16 & 15/2538, Khandagirivihar, Khandagiri, Bhubaneswar, Odisha, PIN- 751030 */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="flex flex-wrap justify-between items-center w-full py-2 px-6 text-sm bg-[#0a0b0b] text-white tracking-wider">
        <div>
          Privacy Policy | Terms & Conditions
          <span> &nbsp;© copyright {currentYear}, </span>
          <span className="text-primaryColor font-semibold">ASSAViO</span>
        </div>

        <div className="mr-4">
          <span
            className="text-[#fe8740] cursor-pointer"
            onClick={() => window.open("https://webbocket.com/")}
          >
            Web_Bocket PVT LTD.
          </span>
          All Rights Reserved.
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserFooter;
