import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { motionVariantsBot, motionProperties } from "../common/MotionVarients";

const synonyms = [
  "got it handled.",
  "got it sorted.",
  "got your back.",
  "got you covered.",
  "got it secured.",
  "Handled for you.",
  "Under control."
]

const fadeOutInVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const ChangingHeader = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % synonyms.length);
    }, 3000); // Change the interval as needed
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.h1
      variants={motionVariantsBot}
      {...motionProperties}
      className="font-bold font-josefin tracking-wide xl:text-6xl lg:text-4xl md:text-5xl text-3xl xl:leading-[70px] md:leading-[70px] leading-[40px]"
    >
      <div className="">
        <div>From Listing to Keys, we’ve</div>
        <AnimatePresence mode="wait">
          <motion.span
            key={synonyms[index]}
            variants={fadeOutInVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="inline-block"
          >
            {` ${synonyms[index]}`}
          </motion.span>
        </AnimatePresence>
      </div>
    </motion.h1>
  );
};

export default ChangingHeader;
