import React, { useEffect, useState } from 'react';
import UserHeader from '../../components/common/UserHeader';
import { useNavigate } from 'react-router-dom';
import ApplicationModal from '../../components/careerModal/ApplicationModal';

const Career = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});

    const careerInfo = [
        {
            "id": 1,
            "role": "Digital Marketing Associate",
            "type": "Full-Time",
            "location": "Bhubaneswar",
            "job_desc" : "As a Digital Marketing Associate at Assavio, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
        },
        {
            "id": 2,
            "role": "Marketing Manager",
            "type": "Full-Time",
            "location": "Bhubaneswar",
            "job_desc" : "As a Marketing Manager at Assavio, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
        },
        {
            "id": 3,
            "role": "Business Development Executive",
            "type": "Full-Time",
            "location": "Bhubaneswar",
            "job_desc" : "As a BDE at Assavio, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
        },
        {
            "id": 4,
            "role": "Telecaller",
            "type": "Full-Time",
            "location": "Bhubaneswar",
            "job_desc" : "As a Telecaller at Assavio, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
        },
        {
            "id": 5,
            "role": "HR Generalist",
            "type": "Full-Time",
            "location": "Bhubaneswar",
            "job_desc" : "As a HR Generalist at Assavio, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
        },
        {
            "id": 6,
            "role": "Project Coordinator",
            "type": "Full-Time",
            "location": "Bhubaneswar",
            "job_desc" : "As a Project Coordinator at Assavio, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
        },
    ]

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    return (
        <React.Fragment>
            <UserHeader
                pageName="Career"
                mainText="Home"
            />

            {showModal
                &&
                (
                    <ApplicationModal
                        data={data}
                        closeModal={toggleModal}
                    />
                )
            }

            <div className="bg-white py-6">
                <div className="max-w-screen-xl mx-auto px-6">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                            Join Our Team
                        </h1>
                        <p className="text-base text-gray-500 mt-4 tracking-wider">
                            Start your career with a leading real estate company<br /> and help us shape the future of real estate.
                        </p>
                    </div>

                    <div className="mt-6">
                        <h2 className="text-2xl font-semibold text-gray-800">
                            Open Positions
                        </h2>
                        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {
                                careerInfo?.map((data, i) => {
                                    return (
                                        <div
                                            key={i}
                                            onClick={() => {
                                                toggleModal()
                                                setData(data)
                                            }}
                                            className="border p-4 rounded-lg shadow hover:bg-[#DEFFCD]">
                                            <h3 className="text-lg font-bold text-primaryColor tracking-wider">
                                                {data?.role}
                                            </h3>
                                            <div className='flex items-center justify-between'>
                                                <div>
                                                    <p className="mt-1 text-sm font-semibold text-gray-800 tracking-wider">
                                                        {data?.type}
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-600 tracking-wider">
                                                        {data?.location}
                                                    </p>
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        toggleModal()
                                                        setData(data)
                                                    }}
                                                    className='bg-blue-500 text-white px-4 py-1 rounded-md hover:scale-105 transition duration-100 ease-linear'>
                                                    More Details
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="mt-12">
                        <h2 className="text-2xl font-semibold text-gray-800 tracking-wider">
                            Why Work With Us?
                        </h2>
                        <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-6">
                            <div className="text-center">
                                <div className="p-4 border rounded-lg shadow">
                                    <h4 className="font-bold text-lg tracking-wider">
                                        Innovative Environment
                                    </h4>
                                    <p className="mt-2 text-sm text-gray-600 tracking-wider">
                                        Join a creative team that's committed to redefining the real estate industry.
                                    </p>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="p-4 border rounded-lg shadow">
                                    <h4 className="font-bold text-lg tracking-wider">
                                        Employee Benefits
                                    </h4>
                                    <p className="mt-2 text-sm text-gray-600 tracking-wider">
                                        Enjoy competitive salaries, health benefits, and paid time off.
                                    </p>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="p-4 border rounded-lg shadow">
                                    <h4 className="font-bold text-lg tracking-wider">
                                        Growth Opportunities
                                    </h4>
                                    <p className="mt-2 text-sm text-gray-600 tracking-wider">
                                        We provide professional development opportunities to help you reach your full potential.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-12 text-center">
                        <h2 className="text-2xl font-semibold text-gray-800 tracking-wider">Ready to Start?</h2>
                        <p className="mt-2 text-sm text-gray-500 tracking-wider">
                            If you’re excited to be part of a winning team,<br /> Assavio Real Estate is a great place to grow your career.
                        </p>

                        <button
                            onClick={() => navigate("/contact")}
                            className="tracking-wider mt-4 px-6 py-3 bg-green-500 text-white font-semibold rounded hover:bg-green-600">
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Career
