import React, { useEffect } from 'react';
import Banner from '../../components/home/Banner';
// import PropertyCity from '../../components/home/PropertyCity';
import TopServices from '../../components/home/TopServices';
import HomeAboutUs from '../../components/home/HomeAboutUs';
import LatestProject from '../../components/home/LatestProject';
import HowWeWork from '../../components/home/HowWeWork';
import VideoBanner from '../../components/home/VideoBanner';

const Home = () => {
    return (
        <div>
            <Banner />
            {/* <PropertyCity /> */}
            <TopServices />
            <HomeAboutUs />
            <LatestProject />
            <VideoBanner />
            <HowWeWork />
        </div>
    );
};

export default Home;
