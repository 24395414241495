import React from "react";
import image from "../assets/errorPage.png";

const ErrorPage = () => {
  return (
    <div>
      <div className="lg:w-[80%] w-[90%] m-auto">
        <img src={image} alt="error img" className="w-full h-[80vh]" />
      </div>
    </div>
  );
};

export default ErrorPage;
