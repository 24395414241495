import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Profile = () => {
    const [profileData, setProfileData] = useState({});
    const userData = JSON.parse(localStorage.getItem("adminInfo"))
    // console.log("profileData", profileData)

    useEffect(() => {
        findAdmin()
    }, [])

    const findAdmin = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/admin/get-all-admins`,
            headers: {},
        };

        axios.request(config)
            .then((response) => {
                setProfileData(response?.data?.find(ele => ele?._id === userData?._id))
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <div className='h-[100vh] overflow-y-scroll bg-gray-900'>
            <section className="w-full overflow-hidden ">
                <div className="flex flex-col">
                    <img
                        src="https://img.freepik.com/free-photo/photorealistic-galaxy-background_23-2151064350.jpg?t=st=1713593769~exp=1713597369~hmac=d9ed688e67354b3d7c62e8c0399e880c3a751acf09c68a3f444ab58f8356403d&w=2000"
                        alt="User Cover"
                        className="w-full xl:h-[20rem] lg:h-[18rem] md:h-[16rem] sm:h-[14rem] xs:h-[11rem]"
                    />
                    <div className="sm:w-[80%] xs:w-[90%] mx-auto flex md:flex-row flex-col">
                        <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSasJmZ20tZ7-v6MCbWlIEd-Q49BKqeiN7ymtbHprn2IA&s"
                            alt="User Profile"
                            className="rounded-md lg:w-[12rem] lg:h-[12rem] md:w-[10rem] md:h-[10rem] sm:w-[8rem] sm:h-[8rem] xs:w-[7rem] xs:h-[7rem] outline outline-2 outline-offset-2 outline-blue-500 relative lg:bottom-[5rem] sm:bottom-[4rem] xs:bottom-[3rem]"
                        />
                        <h1 className="w-full text-left my-4 sm:mx-4 xs:pl-4 text-gray-800 dark:text-white lg:text-4xl md:text-3xl sm:text-3xl xs:text-xl font-serif">
                            {profileData?.name}
                        </h1>
                    </div>

                    <div className=" flex flex-col gap-4 items-center relative lg:-top-8 md:-top-6 sm:-top-4 xs:-top-4 w-[90%] m-auto">
                        <p className="w-fit text-gray-700 dark:text-gray-400 text-md">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam debitis labore consectetur
                            voluptatibus mollitia dolorem veniam omnis ut quibusdam minima sapiente repellendus asperiores
                            explicabo, eligendi odit, dolore similique fugiat dolor, doloremque eveniet. Odit, consequatur.
                            Ratione voluptate exercitationem hic eligendi vitae animi nam in, est earum culpa illum
                            aliquam.
                        </p>

                        <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                            <div className="w-full flex sm:flex-row xs:flex-col gap-2 justify-center">
                                <div className="w-full">
                                    <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                                        <div className="flex flex-col pb-3">
                                            <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400"> Name</dt>
                                            <dd className="text-lg font-semibold">{profileData?.name}</dd>
                                        </div>
                                        {/* More <dt> and <dd> pairs */}
                                    </dl>
                                </div>
                                <div className="w-full">
                                    <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                                        <div className="flex flex-col pb-3">
                                            <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Location</dt>
                                            <dd className="text-lg font-semibold">{profileData?.address}</dd>
                                        </div>
                                        {/* More <dt> and <dd> pairs */}
                                    </dl>
                                </div>
                            </div>

                            {/* <div className="my-10 h-[400px] w-full">
                                <h1 className="w-fit font-serif my-4 pb-1 pr-2 rounded-b-md border-b-4 border-blue-600 dark:border-b-4 dark:border-yellow-600 dark:text-white lg:text-4xl md:text-3xl xs:text-xl">
                                    My Location
                                </h1>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3796.7434393754693!2d85.7800463808636!3d20.253405765526132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19098aa66063cd%3A0x55d0623fa712cd29!2sWeb_Bocket!5e1!3m2!1sen!2set!4v1713593984230!5m2!1sen!2set"
                                    className="rounded-lg w-full h-full"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Profile;
