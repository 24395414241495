import React from 'react'
import image1 from '../../assets/home/client_icon.svg'
import image2 from '../../assets/home/assesment.svg'
import image3 from '../../assets/home/evaluet.svg'
import image4 from '../../assets/home/siteVisit.svg'
import image5 from '../../assets/home/closeTheDeal.svg'
// import image6 from '../../assets/home/plot_sale.svg'
import image6 from '../../assets/home/plotSale_icon.svg'

const data = [
    {
        image: image1,
        title: "Client Consultation",
        para: "Assavio's team of real estate experts conducts an in-depth analysis of the client's requirements. This involves understanding the client's preferences, budget, desired location, and any specific features or amenities they are looking for in a property."
    },
    {
        image: image2,
        title: "Needs Assessment",
        para: "Based on the client's requirements, Assavio performs a thorough needs assessment to identify the client's priorities and preferences. This helps them tailor their property search and services to meet the client's specific needs and expectations."
    },
    {
        image: image3,
        title: "Evaluate the Authenticity",
        para: "Assavio places a high emphasis on verifying the authenticity of properties to ensure transparency and reliability for their clients. They conduct comprehensive background checks and inspections to evaluate the authenticity and legality of each property."
    },
    {
        image: image4,
        title: "Site Visit & Presentation",
        para: "Once potential properties that meet the client's criteria are identified and verified, Assavio presents them to the client. This includes providing detailed information about each property, such as photos, descriptions, floor plans, and pricing. Clients are also invited to visit the properties in person for a site tour to assess their suitability."
    },
    {
        image: image5,
        title: "Close the Deal",
        para: "Assavio assists the client in negotiating the terms of the purchase or lease agreement with the seller or landlord. They leverage their expertise and market knowledge to advocate on behalf of the client and secure favorable terms and conditions."
    },
    {
        image: image6,
        title: "Post - Sale Support",
        para: "After the transaction is completed, Assavio continues to provide support to the client with any post-sale needs they may have. This could include assistance with move-in logistics, property management services, or recommendations for home improvement professionals."
    },
]

const HowWeWork = () => {
    return (
        <div className='px-10 py-10 tracking-wide'>
            <div className='text-center'>
                <p className='text-primaryColor'>
                    Some Features
                </p>

                <h1 className='text-4xl mt-2'>
                    How We Work
                </h1>

                <p className='text-secondaryTextColor w-[60%] m-auto mt-2'>
                    Streamlined, transparent, and client-focused. We guide you through each step of the real estate process, ensuring a smooth and successful journey from start to finish.
                </p>
            </div>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-8'>
                {
                    data?.map((ele) =>
                        <div className='p-4'>
                            <img
                                className='m-auto'
                                src={ele?.image}
                                alt={ele?.title+" "+"Icons"}
                                 />

                            <h1 className='mt-4 text-center'>
                                {ele?.title}
                            </h1>

                            <p className='text-sm text-center text-secondaryTextColor mt-4'>
                                {ele?.para}
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default HowWeWork