import React, { useEffect } from "react";
import UserHeader from "../../../components/common/UserHeader";
import { FaLinkedin, FaLinkedinIn } from "react-icons/fa6";
import testimonial_male from "../../../assets/testimonial_male.svg";
import testimonial_female from "../../../assets/testimonial_female.svg";
import Star1Yellow from "../../../assets/Star1Yellow.svg";

const Testimonials = () => {
  useEffect(() => {
    document.title = `Assavio | Our Testimonials`;
  }, []);

  const testimonialsData = [
    {
      name: "Amit Kumar Mishra",
      gender:'male',
      img_url: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
      msg: "Buying a property can be daunting, but the team at Assavio made the process seamless and stress-free. Their market insights and attention to detail ensured I made a smart investment. The transparency and guidance they provided were invaluable. Thank you for helping me find my dream property!",
    },
    {
      name: "Anita Tiwari",
      gender:'male',
      img_url:
        "https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head.png",
      msg: "I’ve worked with many real estate agents before, but none have shown the market expertise and dedication as our agent, Anita Rodriguez did. Thanks to Assavio insights, we've successfully expanded our investment portfolio. She’s our go-to for all real estate investments now!",
    },
    {
      name: "Manash Tripathy",
      gender:'male',
      img_url: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
      msg: "The dedication and hard work of the team at Assavio were remarkable. From initial consultation to finalizing the deal, they were thorough and proactive. Their understanding of the Bangalore real estate market is unparalleled. I felt confident and well-informed throughout the purchasing process.",
    },
    {
      name: "Govind Jain",
      gender:'male',
      img_url: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
      msg: "When we needed a bigger home to accommodate our growing family, we turned to Greg Daniels. He listened carefully to what we wanted and found us a home that checks all the boxes and then some. We are so happy in our new space and can't thank Greg enough!",
    },
    {
      name: "Mr. Binod Mohapatra",
      gender:'male',
      img_url: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
      msg: "When it comes to luxury properties, our agent, Charles Webb, is truly a cut above. His discretion, attention to detail, and deep understanding of high-end markets led us to a stunning property that exceeded our expectations.",
    },
    {
      name: "Ms. Puja",
      gender:'female',
      img_url:
        "https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head.png",
      msg: "After deciding to downsize, we were so grateful for our agent's expertise and sensitivity to our needs. She helped us find a smaller home that still feels just right, managing every detail with care and precision.",
    },
  ];
  return (
    <React.Fragment>
      <UserHeader pageName="Our Testimonials" mainText="Home" />

      <section className="text-gray-600 body-font">
        <div className="container md:p-10 p-5 mx-auto">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            {testimonialsData?.map((data, i) => {
              return (
                <div
                  key={i}
                  className=" w-full xl:h-[350px] md:h-[300px] h-[200px]"
                  //   style={{
                  //     backgroundImage: `url(${testimonial_male})`,
                  //     backgroundSize: "cover",
                  //     backgroundPosition: "center",
                  //   }}
                >
                  <img
                    src={data?.gender==='male'?testimonial_male:testimonial_female}
                    alt=""
                    className="w-full h-full"
                  />
                  <div className=" xl:w-[80%] lg:w-[90%] md:w-[60%] w-[70%]  xl:ml-10 lg:ml-5 md:ml-[120px] ml-12 text-white relative xl:mt-[-210px] md:mt-[-180px] mt-[-125px]">
                    <p
                      className="text-sm tracking-wide"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        "@media (min-width: 768px)": {
                          WebkitLineClamp: "3",
                        },
                      }}
                    >
                      {data?.msg}
                    </p>

                    <div className="flex gap-1 justify-center xl:mt-2">
                      <img
                        src={Star1Yellow}
                        alt="star svg"
                        className="md:w-5 w-3"
                      />
                      <img
                        src={Star1Yellow}
                        alt="star svg"
                        className="md:w-5 w-3"
                      />
                      <img
                        src={Star1Yellow}
                        alt="star svg"
                        className="md:w-5 w-3"
                      />
                      <img
                        src={Star1Yellow}
                        alt="star svg"
                        className="md:w-5 w-3"
                      />
                      <img
                        src={Star1Yellow}
                        alt="star svg"
                        className="md:w-5 w-3"
                      />
                    </div>
                    <div className="flex justify-center xl:mt-2 gap-2 items-center">
                      <h1 className="md:text-lg text-sm">{data?.name}</h1>
                      <button className="bg-blue-500 p-[2px] h-5 w-5 rounded">
                        <FaLinkedinIn color="white" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Testimonials;
