import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CiLocationOn } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

const Lands = () => {
    const navigate = useNavigate();
    const [landsData, setLandsData] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedPriceRange, setSelectedPriceRange] = useState('');
    const [filteredLandsData, setFilteredLandsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getLandsData = () => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/lands/get-all-lands`)
            .then(response => {
                setLandsData(response?.data);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching lands data:', error);
                setLoading(false)
            });
    };

    useEffect(() => {
        getLandsData();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [selectedCity, selectedType, selectedPriceRange, landsData]);

    const applyFilters = () => {
        let filteredData = landsData.filter(land => {
            let passCity = selectedCity ? land.locationDetails.city === selectedCity : true;
            let passType = selectedType ? land.generalInfo.propertyType === selectedType : true;
            // Implement price range filtering here if needed
            return passCity && passType;
        });

        // Sorting based on price range
        if (selectedPriceRange === 'asc') {
            filteredData.sort((a, b) => a.financialInfo.sellingPrice - b.financialInfo.sellingPrice);
        } else if (selectedPriceRange === 'desc') {
            filteredData.sort((a, b) => b.financialInfo.sellingPrice - a.financialInfo.sellingPrice);
        }

        setFilteredLandsData(filteredData);
    };

    const clearFilters = () => {
        setSelectedCity('');
        setSelectedType('');
        setSelectedPriceRange('');
        setFilteredLandsData([]);
    };
    const isFilterApplied = selectedCity || selectedType || selectedPriceRange;

    const districtsOfOdisha = [
        "Angul",
        "Balangir",
        "Balasore",
        "Bargarh",
        "Bhadrak",
        "Boudh",
        "Cuttack",
        "Debagarh",
        "Dhenkanal",
        "Gajapati",
        "Ganjam",
        "Jagatsinghpur",
        "Jajpur",
        "Jharsuguda",
        "Kalahandi",
        "Kandhamal",
        "Kendrapara",
        "Kendujhar",
        "Khordha",
        "Koraput",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nayagarh",
        "Nuapada",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundergarh"
    ];

    return (
        <div>
            <div className='bg-[#C0FAC9] p-8 rounded-lg'>
                <div className='flex md:flex-row flex-col items-center gap-4 '>
                    <div className='flex md:flex-row flex-col items-center justify-center gap-4 w-full'>
                        <select
                            className='py-3 px-4 rounded bg-white w-full'
                            value={selectedCity}
                            onChange={e => setSelectedCity(e.target.value)}
                        >
                            <option value="">Select District</option>
                            {
                                districtsOfOdisha?.map((dist, i) => {
                                    return (
                                        <option key={i} value={dist}>
                                            {dist}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <select
                            className='py-3 px-4 rounded bg-white w-full'
                            value={selectedType}
                            onChange={e => setSelectedType(e.target.value)}
                        >
                            <option value="">Select Type</option>
                            <option value="agricultural">Agricultural</option>
                            <option value="residential">Residential</option>
                            <option value="commercial">Commercial</option>
                            <option value="industrial">Industrial</option>
                        </select>
                        <select
                            className='py-3 px-4 rounded bg-white w-full'
                            value={selectedPriceRange}
                            onChange={e => setSelectedPriceRange(e.target.value)}
                        >
                            <option value="">Sorting by Price</option>
                            <option value="asc">Low to High</option>
                            <option value="desc">High to Low</option>
                        </select>
                    </div>
                    <button
                        className={`  px-8 py-3 rounded-md text-sm flex items-center gap-2 ${isFilterApplied ? 'bg-primaryBtnColor hover:bg-primaryBtnHoverColor text-white' : 'bg-gray-200 cursor-not-allowed text-gray-400'}`}
                        onClick={clearFilters}
                        disabled={!isFilterApplied}
                    >
                        Clear
                    </button>
                </div>
            </div>

            {
                loading &&
                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4'>
                    {
                        Array.from({ length: 6 }).map((_, index) => (
                            <div key={index} className="rounded-lg overflow-hidden animate-pulse shadow-md w-full">
                                <div className="bg-gray-300 h-60 w-full" />
                                <div className="p-4 space-y-2">
                                    <div className="bg-gray-300 h-4 w-1/2" />
                                    <div className="bg-gray-300 h-4 w-3/4" />
                                    <div className="bg-gray-300 h-4 w-2/3" />
                                    <div className="bg-gray-300 h-4 w-full" />
                                    <div className="bg-gray-300 h-4 w-1/3" />
                                </div>
                            </div>
                        ))
                    }
                </div>
            }

            {
                !loading &&
                <div className={`${filteredLandsData.length === 0 ? 'flex' : "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1"}  gap-4 mt-4`}>
                    {
                        filteredLandsData.length === 0 ?
                            <div className='text-center text-gray-500 p-4 border rounded-lg w-full'>No properties found.</div>
                            :
                            filteredLandsData.map(land => (
                                <div
                                    onClick={() => {
                                        navigate(`/lands/${land._id}`);
                                        window.scrollTo(0, 0);
                                    }}
                                    key={land._id} className='rounded-lg overflow-hidden shadow-md'>
                                    <div className='h-60 overflow-hidden'>
                                        <img src={land.propertyImages[0]} alt=" Building img" className='h-full w-full object-cover hover:scale-125 transition duration-1000' />
                                    </div>
                                    {/* <img src={land.propertyImages[0]} alt="Land property" className='h-60 w-full object-cover' /> */}
                                    <div className='p-4'>
                                        <h1 className="text-lg font-semibold">{land.generalInfo.propertyName}</h1>
                                        <div className='flex gap-2 items-center mt-2 mb-4'>
                                            <CiLocationOn size={20} className='text-primaryColor' />
                                            <p className='text-secondaryTextColor text-xs md:text-sm truncate'>
                                                {land.locationDetails.address}, {land.locationDetails.city}, {land.locationDetails.state}, {land.locationDetails.country}, {land.locationDetails.zipCode}
                                            </p>
                                        </div>
                                        <hr />
                                        <div className='flex items-center justify-end mt-4'>
                                            {/* <h1 className="text-lg font-bold">₹ {land.financialInfo.sellingPrice}</h1> */}
                                            <button
                                                className='flex items-center gap-2 bg-primaryColor text-white hover:bg-primaryBtnHoverColor px-4 py-1 rounded-md'>
                                                Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                    }
                </div>
            }
        </div >
    );
};

export default Lands;
