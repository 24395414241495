import axios from "axios";
import React, { useEffect, useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import {
  FaCar,
  FaCertificate,
  FaHouseMedical,
  FaTowerObservation,
} from "react-icons/fa6";
import { IoCubeOutline } from "react-icons/io5";
import { LiaCertificateSolid } from "react-icons/lia";
import { MdOutlineBathtub } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../components/common/FirstLetterCapitalize";

const Buildings = () => {
  const localStarageFilter = JSON.parse(localStorage.getItem("filter"));
  const [buildingData, setBuildingData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(
    localStarageFilter?.city || ""
  );
  const [selectedType, setSelectedType] = useState(
    localStarageFilter?.type || ""
  );
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getBuildingDatas = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/buildings/get-all-buildings`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setBuildingData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getBuildingDatas();
  }, []);

  const applyFilters = () => {
    let filteredData = buildingData.filter((building) => {
      let passDistrict = selectedDistrict
        ? building.locationDetails.city === selectedDistrict
        : true;
      let passType = selectedType
        ? building.units[0].unitType === selectedType
        : true;
      let passStatus = selectedStatus
        ? building.generalInfo.currentStatus === selectedStatus
        : true;

      return passDistrict && passType && passStatus;
    });

    if (selectedPriceRange === "asc") {
      filteredData.sort(
        (a, b) => a.financialInfo.sellingPrice - b.financialInfo.sellingPrice
      );
    } else if (selectedPriceRange === "desc") {
      filteredData.sort(
        (a, b) => b.financialInfo.sellingPrice - a.financialInfo.sellingPrice
      );
    }

    return filteredData;
  };

  const handleClearFilters = () => {
    setSelectedDistrict("");
    setSelectedType("");
    setSelectedPriceRange("");
    localStorage.removeItem("filter");
  };

  const isFilterApplied =
    selectedDistrict || selectedType || selectedPriceRange;
  const filteredBuildings = applyFilters();

  const districtsOfOdisha = [
    "Angul",
    "Balangir",
    "Balasore",
    "Bargarh",
    "Bhadrak",
    "Boudh",
    "Cuttack",
    "Debagarh",
    "Dhenkanal",
    "Gajapati",
    "Ganjam",
    "Jagatsinghpur",
    "Jajpur",
    "Jharsuguda",
    "Kalahandi",
    "Kandhamal",
    "Kendrapara",
    "Kendujhar",
    "Khordha",
    "Koraput",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nuapada",
    "Puri",
    "Rayagada",
    "Sambalpur",
    "Subarnapur",
    "Sundergarh",
  ];

  return (
    <div>
      <div className="bg-[#C0FAC9] p-8 rounded-lg">
        <div className="flex md:flex-row flex-col items-center gap-4 ">
          <div className="flex md:flex-row flex-col items-center justify-center gap-4 w-full">
            <select
              className="py-3 px-4 rounded bg-white w-full"
              name="city"
              id="city"
              value={selectedDistrict}
              onChange={(e) => setSelectedDistrict(e.target.value)}
            >
              <option value="">Select District</option>
              {districtsOfOdisha?.map((dist, i) => {
                return (
                  <option key={i} value={dist}>
                    {dist}
                  </option>
                );
              })}
            </select>

            <select
              className="py-3 px-4 rounded bg-white w-full"
              name="type"
              id="type"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value="1 BHK">1 BHK</option>
              <option value="2 BHK">2 BHK</option>
              <option value="3 BHK">3 BHK</option>
              <option value="4 BHK">4 BHK</option>
              <option value="Villa">VILLA</option>
            </select>
            <select
              className="py-3 px-4 rounded bg-white w-full"
              name="sorting"
              id="sorting"
              value={selectedPriceRange}
              onChange={(e) => setSelectedPriceRange(e.target.value)}
            >
              <option value="">Sorting by Price</option>
              <option value="asc">Low to High</option>
              <option value="desc">High to Low</option>
            </select>
          </div>
          <button
            className={`  px-8 py-3 rounded-md text-sm flex items-center gap-2 ${
              isFilterApplied
                ? "bg-primaryBtnColor hover:bg-primaryBtnHoverColor text-white"
                : "bg-gray-200 cursor-not-allowed text-gray-400"
            }`}
            onClick={handleClearFilters}
            disabled={!isFilterApplied}
          >
            Clear
          </button>
        </div>
      </div>

      {loading && (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="rounded-lg overflow-hidden animate-pulse shadow-md w-full"
            >
              <div className="bg-gray-300 h-60 w-full" />{" "}
              {/* Adjust height for smaller devices */}
              <div className="p-4 space-y-2">
                {" "}
                {/* Use space-y utility for consistent spacing */}
                <div className="bg-gray-300 h-4 w-1/2" />
                <div className="bg-gray-300 h-4 w-3/4" />
                <div className="bg-gray-300 h-4 w-2/3" />
                <div className="bg-gray-300 h-4 w-full" />
                <div className="bg-gray-300 h-4 w-1/3" />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex lg:flex-row flex-col gap-4">
        <div className="lg:w-[30%] xl:w-[20%]  mt-5">
          <div className="bg-gray-200 border p-4 flex flex-col gap-4 rounded-md">
            <h1 className="text-primaryColor">Sort properties by category</h1>
            <div className="flex items-center justify-between">
              <label htmlFor="default">Default</label>
              <input
                type="radio"
                id="default"
                name="status"
                onChange={() => setSelectedStatus("")}
                checked={selectedStatus === ""}
              />
            </div>
            <div className="flex items-center justify-between">
              <label htmlFor="completed">Completed</label>
              <input
                type="radio"
                id="completed"
                name="status"
                onChange={() => setSelectedStatus("completed")}
                checked={selectedStatus === "completed"}
              />
            </div>
            <div className="flex items-center justify-between">
              <label htmlFor="pre-lunch">Pre - Lunch</label>
              <input
                type="radio"
                id="pre-lunch"
                name="status"
                onChange={() => setSelectedStatus("pre-lunch")}
                checked={selectedStatus === "pre-lunch"}
              />
            </div>
            <div className="flex items-center justify-between">
              <label htmlFor="under-construction">Under Construction</label>
              <input
                type="radio"
                id="under-construction"
                name="status"
                onChange={() => setSelectedStatus("under construction")}
                checked={selectedStatus === "under construction"}
              />
            </div>
            <div className="flex items-center justify-between">
              <label htmlFor="upcoming">Upcoming</label>
              <input
                type="radio"
                id="upcoming"
                name="status"
                onChange={() => setSelectedStatus("upcoming")}
                checked={selectedStatus === "upcoming"}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-[70%] xl:w-[80%]">
          {!loading && (
            <div
              className={`${
                filteredBuildings.length === 0
                  ? "flex items-center justify-center"
                  : "grid md:grid-cols-2 xl:grid-cols-3 gap-4"
              } mt-5`}
            >
              {filteredBuildings.length === 0 ? (
                <div className="text-center text-gray-500 p-4 border rounded-lg w-full">
                  No buildings found.
                </div>
              ) : (
                applyFilters()?.map((ele) => (
                  <div
                    onClick={() => {
                      navigate(`/property/${encodeURIComponent(ele?.generalInfo?.BuildingsName.replace(/\s+/g, '-'))}/${ele?._id}`, { state: { ele } });
                    }}
                    key={ele?._id}
                    className="rounded-lg overflow-hidden shadow-md cursor-pointer"
                  >
                    <div className="h-60 overflow-hidden">
                      <img
                        src={ele?.BuildingsImages[0]}
                        alt=" Building img"
                        className="h-full w-full object-cover hover:scale-125 transition duration-1000"
                      />
                    </div>
                    {/* <img src={ele?.BuildingsPlansImage} alt="Building" className='h-60 w-full object-cover' /> */}
                    <div className="p-4">
                      <h1 className="text-lg">
                        {capitalizeFirstLetter(ele?.generalInfo?.BuildingsName)}
                      </h1>
                      <div className="flex gap-2 items-center mt-2">
                        <CiLocationOn size={20} className="text-primaryColor" />
                        <p className="text-secondaryTextColor text-xs md:text-sm">
                          {capitalizeFirstLetter(ele?.locationDetails?.address)}
                          ,{capitalizeFirstLetter(ele?.locationDetails?.city)},
                          {capitalizeFirstLetter(ele?.locationDetails?.state)},
                          {capitalizeFirstLetter(ele?.locationDetails?.country)}
                          ,
                          {capitalizeFirstLetter(ele?.locationDetails?.zipCode)}
                        </p>
                      </div>

                      <div className="flex items-center gap-4 mt-6 mb-4">
                        <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                          <FaTowerObservation className="text-primaryColor" />
                          <p>
                            {ele?.physicalCharacteristics?.numberOfTowers || 0}
                          </p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                          <FaHouseMedical className="text-primaryColor" />
                          <p>{ele?.units?.length}</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                          <LiaCertificateSolid className="text-primaryColor" />
                          <p>{ele?.generalInfo?.approval}</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                          <IoCubeOutline className="text-primaryColor" />
                          <p>
                            {ele?.physicalCharacteristics?.totalArea || 0} acre
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center justify-between mt-4">
                        <h1>
                          {capitalizeFirstLetter(
                            ele?.generalInfo?.currentStatus
                          )}
                        </h1>
                        <button className="flex items-center gap-2 bg-primaryColor text-white hover:bg-primaryBtnHoverColor px-4 py-1 rounded-md">
                          Details
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Buildings;
