import React from "react";
import image1 from "../../assets/home/discover.jpeg";
import image2 from "../../assets/home/property_setting.jpeg";
import image3 from "../../assets/home/investment.jpeg";
import image4 from "../../assets/home/plot_sale.jpeg";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const data = [
  {
    image: image1,
    title: "DISCOVER VERIFIED PROPERTIES",
    path: "/discover-properties",
    paragraph:
      "At Assavio, we understand the importance of trust and transparency when it comes to finding your perfect .....",
  },
  {
    image: image2,
    title: "PROPERTY SELLING",
    path: "/selling-service",
    paragraph:
      "Our selling services are designed to help you sell your property quickly and at the best possible price. Here's how we can assist you",
  },
  {
    image: image3,
    title: "INVESTMENT",
    path: "/investment-services",
    paragraph:
      "Our investment services are tailored to help you identify and capitalize on lucrative investment opportunities in ....",
  },
  {
    image: image4,
    title: "PLOT SALE SUPPORT",
    path: "/post-sale-support",
    paragraph:
      "At Assavio, we understand the importance of trust and transparency when it comes to finding your perfect .....",
  },
];

const TopServices = () => {
  const navigate = useNavigate();

  return (
    <div className="px-10 tracking-wide pb-5 mt-6 ">
      <div className="text-center">
        <p className="text-primaryBtnColor text-xl">Our Real Estate Services</p>
        <h1 className="md:text-4xl text-xl mt-2">Our Top Services</h1>
        <p className="text-secondaryTextColor mt-2 lg:w-[60%] m-auto">
          At Assavio, we offer a comprehensive range of services to meet all
          your real estate needs. Whether you're buying, selling, renting, or
          investing, our team will guide you
        </p>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-8 border">
        {data?.map((ele, index) => (
          <div
            key={index}
            onClick={() => {
              navigate(ele?.path);
              window.scrollTo(0, 0);
            }}
            className="relative border rounded-xl overflow-hidden cursor-pointer group"
          >
            <img
              className="m-auto h-[220px] w-full"
              src={ele?.image}
              alt={ele?.title+" "+"Image"}
            />
            <div className="absolute inset-0 flex flex-col justify-between p-4 lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
              <div className="text-white bg-black bg-opacity-40 p-2 transform lg:-translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                <p className="text-center">{ele?.title}</p>
              </div>
              <div className="text-white bg-black bg-opacity-40 p-2 transform lg:translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                <p className="text-center line-clamp-2">{ele?.paragraph}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopServices;
