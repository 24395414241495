import React, { useRef, useState } from 'react'
import emailjs from "emailjs-com";
import formBgImage from "../../assets/properties/formBgImage.png";

const InquiryForm = () => {
    const initialState = {
        name: "",
        email: "",
        phone: "",
        message: "",
    }
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [changedMsg, setChangedMsg] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {};


        if (!formData?.name) {
            isValid = false;
            errors.name = 'Name  is required';
        }

        if (!formData?.phone) {
            isValid = false;
            errors.phoneNo = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData?.phone)) {
            isValid = false;
            errors.phoneNo = 'Invalid phone number, must be 10 digits';
        }

        if (!formData?.email) {
            isValid = false;
            errors.email = 'Email is required';
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(formData?.email)) {
            isValid = false;
            errors.email = 'Invalid email format';
        }
        setErrors(errors);
        return isValid;
    };

    const form = useRef();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        {
            emailjs
                .sendForm("service_nfyknat", "template_kc4b4qx", form.current, "lUeNyF9ZL2EbTgojp")
                .then(
                    (result) => {
                        console.log(result.text);
                        setFormData(initialState)
                        setLoading(false)
                        setChangedMsg("Your Details Has Been Received. We Will Contact You Soon!");
                    },
                    (error) => {
                        console.log(error.text);
                        setLoading(false);
                        setChangedMsg("Something Went Wrong!.Please Try Again");
                    }
                );
        }

    };
    return (
        <form
        style={{
            backgroundImage: `url(${formBgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
            ref={form}
            onSubmit={handleSubmit}
            className=' px-8 py-10 rounded'
        >
            <h1 className='text-white text-xl text-center mb-4'>Inquiry Form</h1>

            <div className="flex flex-col gap-3 mt-4">
                <input
                    type="text"
                    name="name"
                    placeholder="Enter Your Name"
                    value={formData?.name}
                    onChange={handleChange}
                    className='w-full bg-transparent border text-white px-4 py-2 outline-none  rounded-md'
                />
                {errors.name && <div className="text-red-500 text-sm tracking-wider">
                    {errors.name}
                </div>}

                <input
                    type="text"
                    name="email"
                    placeholder="Enter Your Email"
                    value={formData?.email}
                    onChange={handleChange}
                    className='w-full bg-transparent border text-white px-4 py-2 outline-none  rounded-md'
                />
                {errors.email && <div className="text-red-500 text-sm tracking-wider">
                    {errors.email}
                </div>}

                <input
                    type="text"
                    name="phone"
                    placeholder="Enter Your Phone No."
                    value={formData?.phone}
                    onChange={handleChange}
                    className='w-full bg-transparent border text-white px-4 py-2 outline-none  rounded-md'
                />
                {errors.phoneNo && <div className="text-red-500 text-sm tracking-wider">
                    {errors.phoneNo}
                </div>}

                <textarea
                    name="message"
                    placeholder="message"
                    value={formData?.message}
                    onChange={handleChange}
                    rows="4"
                    className='w-full bg-transparent border text-white px-4 py-2 outline-none  rounded-md'
                />
            </div>

            {changedMsg && <div className="mb-4 text-center text-green-500 mt-2 text-sm tracking-wider">
                {changedMsg}
            </div>}

            {/* <input type="button" value={loading ? "Please Wait.." : "Inquiry Now"} className='bg-primaryColor hover:bg-primaryBtnHoverColor text-white rounded-md w-full py-2' /> */}

            <div className="mt-4 flex justify-center items-center">
                <button
                    disabled={loading}
                    className='bg-primaryColor hover:bg-primaryBtnHoverColor text-white rounded-md w-full py-2' >
                    {loading ? "Please Wait.." : "Inquiry Now"}
                </button>
            </div>
        </form>
    )
}

export default InquiryForm;