import React from "react";
import Aboutframe from "../../assets/Aboutframe.png";

import UserHeader from "../../components/common/UserHeader";
import frame from "../../assets/frame.svg";
import frame1 from "../../assets/image 22.png";
import frame2 from "../../assets/image 24.png";
import frame3 from "../../assets/Expertise and Experience.png";

import frame4 from "../../assets/frame4.png";
import frame5 from "../../assets/frame5.png";
import frame6 from "../../assets/frame6.png";
import frame7 from "../../assets/frame7.png";
import frame8 from "../../assets/frame8.png";
import frame9 from "../../assets/realsse.png";

import arpit from "../../assets/arpit.png";
import sujit from "../../assets/sujit.png";
import santosh from "../../assets/santosh.png";

const Home = () => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <UserHeader headerImg={frame} pageName="About Us" mainText="Home" />

      <div className="p-10">
        <div className="bg-[#c0fac9] p-2 rounded-md  border border-primaryColor flex flex-wrap justify-center gap-2 lg:gap-4 mb-4 lg:mb-10">
          <button
            onClick={() => scrollTo("aboutUsSection")}
            className="h-10 w-36 tracking-wide text-sm font-semibold bg-gray-200 border-2 border-primaryColor hover:border-[#0ea344] hover:text-[#0ea344] hover:font-bold text-primaryTextColor rounded-md cursor-pointer transition ease-in-out hover:duration-300"
          >
            Who Are We
          </button>

          <button
            onClick={() => scrollTo("visionSection")}
            className="h-10 w-36 tracking-wide text-sm font-semibold bg-gray-200 border-2 border-primaryColor hover:border-[#0ea344] hover:text-[#0ea344] hover:font-bold text-primaryTextColor rounded-md cursor-pointer transition ease-in-out hover:duration-300"
          >
            Our Vision
          </button>

          <button
            onClick={() => scrollTo("missionSection")}
            className="h-10 w-36 tracking-wide text-sm font-semibold bg-gray-200 border-2 border-primaryColor hover:border-[#0ea344] hover:text-[#0ea344] hover:font-bold text-primaryTextColor rounded-md cursor-pointer transition ease-in-out hover:duration-300"
          >
            Our Mission
          </button>

          <button
            onClick={() => scrollTo("coreValuesSection")}
            className="h-10 w-36 tracking-wide text-sm font-semibold bg-gray-200 border-2 border-primaryColor hover:border-[#0ea344] hover:text-[#0ea344] hover:font-bold text-primaryTextColor rounded-md cursor-pointer transition ease-in-out hover:duration-300"
          >
            Our Core Values
          </button>

          <button
            onClick={() => scrollTo("whyChooseUs")}
            className="h-10 w-36 tracking-wide text-sm font-semibold bg-gray-200 border-2 border-primaryColor hover:border-[#0ea344] hover:text-[#0ea344] hover:font-bold text-primaryTextColor rounded-md cursor-pointer transition ease-in-out hover:duration-300"
          >
            Why Choose Us?
          </button>

          <button
            onClick={() => scrollTo("approach")}
            className="h-10 w-36 tracking-wide text-sm font-semibold bg-gray-200 border-2 border-primaryColor hover:border-[#0ea344] hover:text-[#0ea344] hover:font-bold text-primaryTextColor rounded-md cursor-pointer transition ease-in-out hover:duration-300"
          >
            Our Approach
          </button>

          <button
            onClick={() => scrollTo("leaders")}
            className="h-10 w-36 tracking-wide text-sm font-semibold bg-gray-200 border-2 border-primaryColor hover:border-[#0ea344] hover:text-[#0ea344] hover:font-bold text-primaryTextColor rounded-md cursor-pointer transition ease-in-out hover:duration-300"
          >
            Our Leaders
          </button>
        </div>

        <div id="aboutUsSection" className="element">
          {/* About Us Content Here */}
          <div className="flex md:flex-row flex-col md:justify-between justify-center">
            <div className="flex flex-col gap-6 tracking-wide md:w-1/2 w-full">
              <div className="text-[#A24319] tracking-wider text-[20px]">
                Get to Know Us Better
              </div>

              <div>
                <h1 className="text-[#212121] font-bold text-3xl w-full">
                  Your Gateway to Bhubaneswar Property Excellence
                </h1>
              </div>

              <p className="text-[#8B8989]  w-full text-[16px] tracking-wide text-justify">
                Welcome to Assavio, where trust is our foundation. At Assavio,
                we understand that real estate transactions are built on trust,
                and we're dedicated to earning and maintaining that trust with
                every client we serve.  We believe in turning dreams into
                reality. Our tagline, "Imagination Meets Reality," encapsulates
                our commitment to helping clients envision their ideal property
                and then bringing that vision to life. Founded on the principles
                of trust and integrity, creativity, and personalized service,
                Assavio is your trusted partner in the journey towards finding
                the perfect property.
              </p>

              <div className="w-full flex justify-center">
                <img className="h-auto w-[80%]" src={frame9} alt="frame9" />
              </div>
            </div>

            <div className="md:w-1/2 w-full flex justify-center">
              <img className="h-auto w-[90%]" src={Aboutframe} alt="frame" />
            </div>
          </div>

          <p className="text-[#8B8989]  w-full text-[16px] tracking-wide text-justify mt-6">
            Founded on the principles of trust and integrity, creativity, and
            personalized service, Assavio is your trusted partner in the journey
            towards finding the perfect property. Our team of experienced
            professionals understands the nuances of the real estate market and
            is dedicated to providing you with expert guidance and support every
            step of the way.Trust is not something that can be bought or sold;
            it is earned through actions and upheld through integrity. At
            Assavio, we are committed to earning and maintaining the trust of
            our clients by delivering on our promises and consistently exceeding
            expectations. From the moment you walk through our doors, you can
            trust that you are in good hands.
            <br /> <br />
            Creativity is at the heart of everything we do. We believe in
            thinking outside the box and finding innovative solutions to meet
            the unique needs of our clients. Whether it's staging a property to
            maximize its appeal, negotiating a complex deal, or marketing a
            listing to reach the right audience, we approach every challenge
            with creativity and enthusiasm.
            <br /> <br />
            At Assavio, we are more than just a real estate company; we are your
            trusted partner in the journey towards finding the perfect property.
            Whether you're buying, selling, renting, or investing, you can trust
            us to guide you through the process with integrity, professionalism,
            and personalized service.
            <br /> <br />
            Our team of experienced professionals is here to help you navigate
            the complexities of the real estate market and achieve your goals
            with confidence and peace of mind. With our unwavering commitment to
            excellence and dedication to your success, you can trust Assavio to
            turn your real estate dreams into reality.
          </p>
        </div>

        <div id="visionSection" className="element">
          {/* Vision Content Here */}
          <div className="flex items-center gap-4 mt-6">
            <img className="" src={frame1} alt="frame" />

            <h2 className="text-2xl font-bold tracking-wider border-b-4 border-[#B52E2B] py-2">
              Our Vision
            </h2>
          </div>

          <p className="text-[#8B8989]  w-full text-[16px] tracking-wide text-justify mt-3">
            At Assavio, our vision is to bridge the gap between imagination and
            reality for our clients. We envision a future where dreams are not
            just dreams but tangible possibilities waiting to be realized. Our
            goal is to inspire and empower our clients to dream big, explore new
            horizons, and turn their real estate aspirations into concrete
            achievements.
          </p>
        </div>

        <div id="missionSection" className="element">
          {/* Mission Content Here */}
          <div className="flex items-center gap-4 mt-6">
            <img className="" src={frame2} alt="frame" />

            <h2 className="text-2xl font-bold tracking-wider border-b-4 border-[#B52E2B] py-2">
              Our Mission
            </h2>
          </div>
          <p className="text-[#8B8989]  w-full text-[16px] tracking-wide text-justify mt-6">
            Our mission at Assavio is simple: to build lasting relationships
            with our clients based on trust, professionalism, and exceptional
            personalized service. Whether you're dreaming of a cozy family home,
            a thriving business location, or a lucrative investment opportunity,
            we're here to guide you through the process with integrity and
            transparency, ensuring you feel confident and empowered every step
            of the way.
          </p>

          <div className="mt-6">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Unlocking Potentials :
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              We believe that every property has the potential to become a dream
              home, a thriving business, or a lucrative investment opportunity.
              Our vision is to unlock this potential and help our clients see
              possibilities where others see only limitations. By combining
              imagination with expertise, we aim to open doors to new
              opportunities and transform visions into reality.
            </span>
          </div>

          <div className="mt-6">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Turning Dreams Into Reality :
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              Whether it's finding the perfect home, securing a prime commercial
              space, or identifying a promising investment property, we're here
              to make dreams come true. Our vision is to guide our clients
              through every step of the real estate journey, from envisioning
              their ideal property to making it a reality. With our personalized
              service, expert guidance, and unwavering dedication, we're
              committed to helping our clients achieve their real estate goals
              and live their dreams.
            </span>
          </div>

          <div className="mt-6">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Innovating for Tomorrow :
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              As visionaries in the real estate industry, we're constantly
              pushing the boundaries of what's possible. Our vision is to be at
              the forefront of innovation, leveraging cutting-edge technology,
              creative thinking, and forward-thinking strategies to
              revolutionize the real estate experience. By embracing change and
              embracing the future, we aim to redefine the way people buy, sell,
              and invest in property.
            </span>
          </div>
        </div>

        <div id="coreValuesSection" className="element">
          {/* Core Values Content Here */}
          <div className="flex justify-center items-center mt-6">
            <h2 className="text-2xl font-bold tracking-wider  border-b-4 border-[#B52E2B] py-2">
              Our Core Values
            </h2>
          </div>

          <div className="flex md:justify-between justify-center md:flex-row flex-col items-center gap-16">
            <div className="mt-6 w-full text-justify">
              <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
                • Creativity :
              </span>

              <span className="text-[#8B8989] text-[16px] tracking-wide">
                We believe in the power of imagination to inspire and innovate.
                At Assavio, we approach every transaction with creativity and
                vision, seeking out unique opportunities and solutions to meet
                our clients' needs.{" "}
              </span>
            </div>

            <div className="mt-6 w-full text-justify">
              <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
                • Integrity :
              </span>

              <span className="text-[#8B8989] text-[16px] tracking-wide">
                Trust and integrity are at the heart of everything we do. You
                can rely on Assavio to always act with honesty, transparency,
                and professionalism, ensuring your best interests are our top
                priority.
              </span>
            </div>
          </div>

          <div className="flex md:justify-between justify-center md:flex-row flex-col items-center gap-16">
            <div className="mt-6 w-full text-justify">
              <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
                • Personalized Service :
              </span>

              <span className="text-[#8B8989] text-[16px] tracking-wide">
                We understand that every client is unique, which is why we
                provide personalized service tailored to your individual needs
                and preferences. From the initial consultation to the closing
                table, we're committed to guiding you through the process with
                care and attention to detail.
              </span>
            </div>

            <div className="mt-6 w-full text-justify">
              <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
                • Commitment to Excellence :
              </span>

              <span className="text-[#8B8989] text-[16px] tracking-wide">
                Excellence is not just a goal; it's a standard we uphold in
                everything we do. Our team of experienced professionals is
                dedicated to delivering exceptional service and results for our
                clients, every step of the way.
              </span>
            </div>
          </div>
        </div>

        <div id="whyChooseUs" className="element">
          <div className="flex justify-center items-center mt-6">
            <h2 className="text-2xl font-bold tracking-wider  border-b-4 border-[#B52E2B] py-2">
              Why Choose Us..?
            </h2>
          </div>

          <div className="grid md:grid-cols-3 gap-4 mt-6">
            <div className="p-4">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src={frame4}
                alt="Rounded avatar"
              />
              <h3 className="text-lg font-semibold text-center mt-4 tracking-wider">
                Trust & Transparency
              </h3>
              <p className="text-[#8B8989] text-justify text-[16px] tracking-wide">
                Trust and transparency are at the core of everything we do. You
                can rely on Assavio to always act with honesty, integrity, and
                professionalism, ensuring your best interests are our top
                priority
              </p>
            </div>

            <div className="p-4">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src={frame3}
                alt="Rounded avatar"
              />
              <h3 className="text-lg font-semibold text-center mt-4 tracking-wider">
                Experience
              </h3>
              <p className="text-[#8B8989] text-justify text-[16px] tracking-wide">
                With a team of seasoned professionals from diverse backgrounds,
                Assavio brings unparalleled expertise and experience to the
                table. Whether you're a first-time homebuyer or an experienced
                investor, we have the knowledge and resources to guide you
                through every step of the process
              </p>
            </div>

            <div className="p-4">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src={frame5}
                alt="Rounded avatar"
              />
              <h3 className="text-lg font-semibold text-center mt-4 tracking-wider">
                Personalized Services
              </h3>
              <p className="text-[#8B8989] text-justify text-[16px] tracking-wide">
                At Assavio, we understand that every client is unique. That's
                why we provide personalized service tailored to your individual
                needs and preferences. From the initial consultation to the
                closing table, we're committed to guiding you through the
                process with care and attention to detail
              </p>
            </div>

            <div className="p-4">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src={frame6}
                alt="Rounded avatar"
              />
              <h3 className="text-lg font-semibold text-center mt-4 tracking-wider">
                Quality Assurance
              </h3>
              <p className="text-[#8B8989] text-justify text-[16px] tracking-wide">
                We take pride in offering a curated selection of verified
                properties that meet our high standards of quality,
                authenticity, and reliability. You can trust that every listing
                we present has been meticulously evaluated to ensure it meets
                your expectations
              </p>
            </div>

            <div className="p-4">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src={frame7}
                alt="Rounded avatar"
              />
              <h3 className="text-lg font-semibold text-center mt-4 tracking-wider">
                Innovation & Creativity
              </h3>
              <p className="text-[#8B8989] text-justify text-[16px] tracking-wide">
                As industry leaders, we're committed to pushing the boundaries
                of what's possible. Our innovative approach, coupled with
                creative thinking, allows us to provide unique solutions and
                opportunities for our clients
              </p>
            </div>

            <div className="p-4">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src={frame8}
                alt="Rounded avatar"
              />
              <h3 className="text-lg font-semibold text-center mt-4 tracking-wider">
                Long Term Relationship
              </h3>
              <p className="text-[#8B8989] text-justify text-[16px] tracking-wide">
                Our commitment to your satisfaction doesn't end when the
                transaction is complete. We believe in building long-term
                relationships with our clients based on trust, integrity, and
                mutual respect. Whether you're a first-time homebuyer or a
                seasoned investor, we'll be by your side every step of the way,
                supporting you through every stage of your real estate journey
              </p>
            </div>
          </div>
        </div>

        <div id="approach" className="element">
          <div className="flex justify-center items-center mt-6">
            <h2 className="text-2xl font-bold tracking-wider  border-b-4 border-[#B52E2B] py-2">
              Our Approach
            </h2>
          </div>

          <div className="text-[#8B8989] text-center w-full text-[16px] tracking-wide mt-6">
            At Assavio, we believe that finding the perfect property is not just
            about listings; it's about understanding your unique needs,
            preferences, and aspirations. That's why our approach is centered
            around providing you with a personalized real estate journey that's
            tailored to your individual goals and desires.
          </div>

          <div className="mt-6 flex flex-col text-justify">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Understanding Your Vision :
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              We begin by taking the time to listen and understand your vision
              for your ideal property. Whether you're searching for a cozy
              family home, a chic urban loft, or a lucrative investment
              opportunity, we'll work closely with you to identify your
              priorities and preferences, ensuring that every aspect of your
              real estate journey is aligned with your vision.
            </span>
          </div>

          <div className="mt-6 flex flex-col text-justify">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Expert Guidance:
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              With years of experience in the real estate industry, our team of
              experts is here to provide you with the guidance and support you
              need to make informed decisions throughout the process. From
              neighborhood insights to market trends, we'll provide you with
              expert advice and resources to help you navigate the complexities
              of the real estate market with confidence and ease.
            </span>
          </div>

          <div className="mt-6 flex flex-col text-justify">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Curated Selection:
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              We understand that your time is valuable, which is why we've
              curated a selection of verified properties that meet our high
              standards of quality, authenticity, and reliability. Whether
              you're browsing our listings online or attending a property
              viewing, you can trust that every property we present to you has
              been carefully vetted and verified to ensure it meets all the
              legal compliance and your expectations.
            </span>
          </div>

          <div className="mt-6 flex flex-col text-justify">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Transparent Communication:
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              Communication is key to a successful real estate journey, which is
              why we prioritize transparency and honesty in all our
              interactions. From clear pricing information to detailed property
              profiles, we'll provide you with all the information you need to
              make informed decisions about your real estate investments,
              empowering you to take control of your future with confidence.
            </span>
          </div>

          <div className="mt-6 flex flex-col text-justify">
            <span className="text-[#163B21] font-semibold text-[16px] tracking-wider">
              • Long-term Relationships:
            </span>

            <span className="text-[#8B8989] text-[16px] tracking-wide">
              Our commitment to your satisfaction doesn't end when the
              transaction is complete. We believe in building long-term
              relationships with our clients based on trust, integrity, and
              mutual respect. Whether you're a first-time homebuyer or an
              experienced investor, we'll be by your side every step of the way,
              supporting you through every stage of your real estate journey.
            </span>
          </div>
        </div>

        <div id="leaders" className="element">
          <div className="flex justify-center items-center mt-6">
            <h2 className="text-2xl font-bold tracking-wider  border-b-4 border-[#B52E2B] py-2">
              MEET OUR LEADERSHIP TEAM MEMBERS
            </h2>
          </div>

          <div className="text-[#8B8989] text-[16px] tracking-wide mt-6">
            At Assavio, our leadership team is comprised of passionate and
            experienced professionals from different industry verticals who are
            dedicated to guiding our company towards excellence and innovation
            in the real estate industry. With a diverse range of expertise and a
            shared commitment to our core values, our leadership team is
            committed to delivering exceptional service and results for our
            clients, our team, and our community.
          </div>

          <div className="flex flex-col lg:flex-row justify-center md:justify-between items-center my-6 space-y-4 md:space-y-0">
            <div className="lg:w-1/2 w-full px-4 md:px-0">
              <img
                className="h-auto lg:w-[80%] w-full mx-auto rounded-md"
                src={santosh}
                alt="frame"
              />
            </div>

            <div className="lg:w-1/2 w-full flex justify-center">
              <div className="text-[#8B8989] text-[16px] tracking-wide text-justify p-4">
                With over 20 years of diverse experience across the Telecom,
                Mining, Insurance & Health sectors, Santosh Kumar Dash brings a
                wealth of knowledge and strategic insight to his role as
                Director of Strategic Partnerships and Public Relations at
                Assavio. With a strong background in the Telecom sector and
                significant contributions to renowned brands in diverse sectors,
                Mr. Dash has demonstrated exceptional leadership qualities
                throughout his career.
                <br />
                <br /> Santosh's extensive experience, coupled with his MBA
                graduate background, equips him with a unique perspective on
                public relations and strategic partnerships. His
                forward-thinking approach and commitment to excellence position
                Assavio as a leader in the Real Estate industry. His tenure as
                CEO demonstrates his ability to lead and inspire teams to
                achieve remarkable results, setting a high standard of
                excellence in every endeavour. As Director of Strategic
                Partnerships and Public Relations, Santosh is dedicated to
                driving growth and expansion for Assavio by forging valuable
                partnerships and opportunities for success. His unwavering
                belief in the power of a well-knit team underscores his
                dedication to ensuring that Assavio consistently delivers
                remarkable results.
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row flex-col-reverse justify-center md:justify-between items-center space-y-4 md:space-y-0">
            <div className="lg:w-1/2 w-full flex justify-center">
              <div className="text-[#8B8989] text-[16px] tracking-wide text-justify p-4">
                As the Director of HR and Operations at Assavio, Sujit Kumar
                Pattanaik plays a pivotal role in driving the company's success
                by overseeing human resources and day-to-day operations. His
                primary focus is on ensuring that our team consistently delivers
                exceptional service to our clients while upholding the highest
                standards of professionalism and integrity. With over 25 years
                of diverse experience and 15 years of extensive experience in
                the advertising sector, including founding NEXVENT Advertising
                with operations in 5 states in India, Mr. Pattanaik brings a
                wealth of knowledge and expertise to his position.
                <br />
                <br /> His background in advertising has equipped him with a
                keen attention to detail and a strong commitment to quality,
                ensuring that operations run smoothly and efficiently at
                Assavio. Mr. Pattanaik is dedicated to cultivating a positive
                and productive work environment. He places emphasis on
                attracting top talent, fostering professional development, and
                ensuring perational efficiency and effectiveness throughout the
                organization. His ultimate goal is to establish Assavio as one
                of the premier workplaces in the industry.
              </div>
            </div>
            <div className="lg:w-1/2 w-full px-4 md:px-0">
              <img
                className="h-auto lg:w-[80%] w-full mx-auto rounded-md"
                src={sujit}
                alt="frame"
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row justify-center md:justify-between items-center my-6 space-y-4 md:space-y-0">
            <div className="lg:w-1/2 w-full px-4 md:px-0">
              <img
                className="h-auto lg:w-[80%] w-full mx-auto rounded-md"
                src={arpit}
                alt="frame"
              />
            </div>
            <div className="lg:w-1/2 w-full flex justify-center">
              <div className="text-[#8B8989] text-[16px] tracking-wide text-justify p-4">
                Arpit Kumar Nayak is a dynamic leader at Assavio, where his
                multifaceted expertise in technology, business strategy, and
                global delivery drives innovation and financial excellence. With
                a distinguished background as Managing Director of KGS
                Technology Group Inc. and founder of Techmentee Pvt Ltd, Arpit
                possesses a strategic acumen honed over years of leadership in
                leveraging technology to propel business growth.
                <br />
                <br />
                Arpit's international experience in the USA and Canada enriches
                his role as Director of Information Technology and Finance at
                Assavio, where he spearheads the implementation of cutting-edge
                technologies to maintain the company's competitive edge in the
                real estate industry. His visionary approach towards integrating
                artificial intelligence into real estate practices not only
                underscores Assavio's commitment to innovation but also
                redefines industry standards.
                <br />
                <br />
                Arpit Nayak spearheads Assavio's marketing initiatives, driving
                brand awareness and visibility. Leveraging his deep
                understanding of market trends and consumer behavior, Arpit
                develops innovative strategies to showcase our properties,
                attracting potential buyers and investors through a seamless
                integration of technology with the real estate industry.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
