// src/Slider.js
import React, { useEffect, useState } from 'react';
import './Slider.css'
import image1 from '../../assets/home/image1.jpeg'
import image2 from '../../assets/home/image2.jpeg'
import image3 from '../../assets/home/image3.webp'
import image4 from '../../assets/home/image4.jpeg'

const slides = [
  {
    image: image3,
    heading: "Ride Fast or Stay Home",
    text: "I try to get away and take my motorcycle on a ride whenever I can. I'll take my bike out before the show and just cruise."
  },
  {
    image: image2,
    heading: "Shine Like a diamond in the Dark",
    text: "People are like stained glass windows, they sparkle and shine when the sun is out, but when darkness sets in their true beauty is revealed only if there is a light from within."
  },
  {
    image: image1,
    heading: "leave sooner, drive slower, live longer",
    text: "driving is not my hobby it's my feeling. I only love FAST CARS because I don't believe slow and steady wins the race."
  },
  {
    image: image4,
    heading: "Girl in Dark",
    text: "If you can make a woman laugh, you can make her do anything. All little girls should be told they are pretty, even if they aren't. A girl should be two things: classy and fabulous."
  }
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute w-full h-[70%] transition-opacity duration-1000 ${index === currentSlide ? 'opacity-100' : 'opacity-0'}`}
          style={{ backgroundImage: `url(${slide.image})`, backgroundPosition: 'center', backgroundSize: 'cover', animation: 'zoom-in-out 8s ease-in-out infinite' }}
        >
        </div>
      ))}
    </div>
  );
};

export default Slider;
