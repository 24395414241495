import React from 'react'

const Setting = () => {
  return (
    <div>
      Setting
    </div>
  )
}

export default Setting
