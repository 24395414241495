import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdOutlineDeleteSweep } from 'react-icons/md';
import { capitalizeFirstLetter } from '../../components/common/FirstLetterCapitalize';

const LandListTable = () => {
    const [landsData, setLandsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/lands/get-all-lands`);
            setLandsData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setFiltersApplied(searchTerm !== '' || filterType !== '' || minPrice !== '' || maxPrice !== '');
    }, [searchTerm, filterType, minPrice, maxPrice]);

    const filterLands = (data) => {
        return data.filter(land => {
            return (
                land.locationDetails.city.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (filterType === '' || land.generalInfo.propertyType.toLowerCase() === filterType.toLowerCase()) &&
                (minPrice === '' || land.financialInfo.sellingPrice >= parseInt(minPrice)) &&
                (maxPrice === '' || land.financialInfo.sellingPrice <= parseInt(maxPrice))
            );
        });
    };

    const landsPerPage = 5;
    const filteredLands = filterLands(landsData);
    const totalPages = Math.ceil(filteredLands.length / landsPerPage);

    const indexOfLastLand = currentPage * landsPerPage;
    const indexOfFirstLand = indexOfLastLand - landsPerPage;
    const currentLands = filteredLands.slice(indexOfFirstLand, indexOfLastLand);

    const handlePrevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterType = (e) => {
        setFilterType(e.target.value);
    };

    const handleMinPriceChange = (e) => {
        setMinPrice(e.target.value);
    };

    const handleMaxPriceChange = (e) => {
        setMaxPrice(e.target.value);
    };

    const clearFilters = () => {
        setSearchTerm('');
        setFilterType('');
        setMinPrice('');
        setMaxPrice('');
    };

    const deleteLands = (id) => {
        // console.log(id)
        setDeleteLoading(true)
        let data = '';

        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/lands/delete-land-by-id/${id}`,
            headers: {},
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                setDeleteLoading(false)
                fetchData()
            })
            .catch((error) => {
                setDeleteLoading(false)
                console.log(error);
            });

    }


    return (
        <div className="container mx-auto">
            {deleteLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
            )}
            <h2 className="text-2xl font-normal ml-6 mt-4 mb-4">Land Lists</h2>

            <div className='px-6 py-2 w-full'>
                <input type="text" placeholder="Search by District" value={searchTerm} onChange={handleSearch} className="px-4 py-2 shadow-lg border rounded outline-none bg-white w-full" />
            </div>
            <div className='w-full px-6 py-2'>
                <select value={filterType} onChange={handleFilterType} className="px-4 py-2 shadow-lg border rounded outline-none bg-white w-full">
                    <option value="">Filter by type</option>
                    <option value="residential">Residential</option>
                    <option value="commercial">Commercial</option>
                </select>
            </div>

            <div className="flex lg:flex-row flex-col gap-2 items-center  justify-between mb-4 px-6 mt-2">
                <div className='lg:flex grid grid-cols-1 items-center gap-1 lg:w-[70%] w-full'>
                    <input type="number" placeholder="Min Price" value={minPrice} onChange={handleMinPriceChange} className="px-4 py-2 shadow-lg border rounded outline-none bg-white" />
                    <input type="number" placeholder="Max Price" value={maxPrice} onChange={handleMaxPriceChange} className="px-4 py-2 shadow-lg border rounded outline-none bg-white" />
                </div>
                <button onClick={clearFilters} className={`px-4 py-2 rounded-md ml-4 ${filtersApplied ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800 cursor-not-allowed'}`} disabled={!filtersApplied}>
                    Clear Filters
                </button>
            </div>

            {loading ? (
                <div className="text-center">Loading...</div>
            ) : (

                <>
                    <div className='px-6'>
                        <div className="overflow-x-auto border rounded-t-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                {/* Table Header */}
                                <thead className="bg-blue-200">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">S no.</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Type</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Address</th>
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Total Area</th>
                                        {/* <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Selling Price</th> */}
                                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Action</th>
                                    </tr>
                                </thead>
                                {/* Table Body */}
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {currentLands.length === 0 ? (
                                        <tr>
                                            <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-center">No data found.</td>
                                        </tr>
                                    ) : (
                                        currentLands.map((land, index) => (
                                            <tr key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap">{indexOfFirstLand + index + 1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(land.generalInfo.propertyName)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(land.generalInfo.propertyType)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(land.generalInfo.currentStatus)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{`${capitalizeFirstLetter(land.locationDetails.address)}, ${capitalizeFirstLetter(land.locationDetails.city)}, ${capitalizeFirstLetter(land.locationDetails.country)}`}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{capitalizeFirstLetter(land.physicalCharacteristics.totalArea)} acres</td>
                                                {/* <td className="px-6 py-4 whitespace-nowrap">${land.financialInfo.sellingPrice}</td> */}
                                                <td onClick={() => deleteLands(land?._id)} className="px-6 py-4 whitespace-nowrap text-red-500 cursor-pointer"><MdOutlineDeleteSweep size={30} /></td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {/* Pagination */}
                        <div className='flex items-center justify-between p-4 border mt-4 rounded-b-lg'>
                            <div className='flex items-center gap-4'>
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1 || currentLands.length === 0}
                                    className={` ${currentPage === 1 || currentLands.length === 0 ? 'text-gray-300 cursor-not-allowed' : 'text-blue-500 hover:text-blue-600'}`}
                                >
                                    Prev
                                </button>
                                <h1>{currentPage}</h1>
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages || currentLands.length === 0}
                                    className={`${currentPage === totalPages || currentLands.length === 0 ? 'text-gray-300 cursor-not-allowed' : 'text-blue-500 hover:text-blue-600'}`}
                                >
                                    Next
                                </button>
                            </div>
                            <p className="text-sm text-gray-500">Page {currentPage} of {totalPages}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LandListTable;
