import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const BlogDetail = () => {
  const { id,title } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = `Assavio | ${title}`;
}, [title]);
  const getBlog = async () => {
    try {
      const response = await axios.get(`https://assavio-realestate.onrender.com/real-estate/blogs/get-blogs/${id}`);
      setBlog(response.data);
    } catch (error) {
      console.error("Error fetching blog details:", error);
    } finally {
      setLoading(false);
    }
  };

  const getRecentBlogs = async () => {
    try {
      const response = await axios.get("https://assavio-realestate.onrender.com/real-estate/blogs/get-all-blogs");
      setRecentBlogs(response.data.blogs.slice(0, 4));
    } catch (error) {
      console.error("Error fetching recent blogs:", error);
    }
  };

  useEffect(() => {
    getBlog();
    getRecentBlogs();
  }, [id]);

  const navigateToDetail = (blogId, blogTitle) => {
    const titleWithDashes = blogTitle.replace(/\s+/g, '-'); // Replace spaces with dashes
    navigate(`/${blogId}/blog/${encodeURIComponent(titleWithDashes)}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 lg:flex lg:gap-8">
      <div className="blog-details mb-12 lg:w-2/3">
        <img
          src={blog.blogImage}
          alt={blog.title}
          className="w-full h-64 object-cover mb-6 rounded-lg"
        />
        <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>

        <div className="blog-content">
          {blog.paragraphs.map((paragraph, index) => (
            <p key={index} className="mb-4">
              {paragraph}
            </p>
          ))}
        </div>
        <div className="mt-4">
          <span className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm">
            {blog.category}
          </span>
        </div>
        <div className="flex items-center mb-4 mt-6">
          <img
            src={blog.authorImage}
            alt={blog.authorName}
            className="w-10 h-10 rounded-full mr-4"
          />
          <span className="text-gray-600">{blog.authorName}</span>
          <span className="text-gray-400 ml-4">
            {new Date(blog.createdAt).toLocaleDateString()}
          </span>
        </div>
      </div>

      <div className="recent-blogs w-full lg:w-1/3">
        <h2 className="text-2xl font-bold mb-4">Recent Blog Posts</h2>
        <div className="grid grid-cols-1 gap-6">
          {recentBlogs.map((recentBlog) => (
            <div
              key={recentBlog._id}
              onClick={() => navigateToDetail(recentBlog._id, recentBlog.title)}
              className="bg-gray-100 p-4 rounded-lg cursor-pointer hover:bg-gray-200"
            >
              <img
                src={recentBlog.blogImage}
                alt={recentBlog.title}
                className="w-full h-40 object-cover mb-2 rounded"
              />
              <h3 className="text-xl font-semibold">{recentBlog.title}</h3>
              <p className="text-gray-600">{recentBlog.sortParagraph}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
