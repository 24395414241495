import React from 'react';

const UpcomingProject = () => {
    return (
        <div className="flex flex-col items-center justify-center py-20 bg-gray-100">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">New Listings Coming Soon!</h1>
            <p className="text-lg text-gray-600 mb-8">We're preparing to unveil exciting new properties. Stay tuned for updates!</p>
            <div className="flex space-x-4">
                <img src="https://i.pinimg.com/originals/67/02/64/67026413319dd5bc391ff5633281b806.gif" alt="" className='w-40 h-30' />
            </div>
        </div>
    );
}

export default UpcomingProject;
