import React, { useEffect, useState } from "react";
import {
  FaArrowRightLong,
  FaHouseMedical,
  FaTowerObservation,
} from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";
import { IoCubeOutline } from "react-icons/io5";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LiaCertificateSolid } from "react-icons/lia";
import { capitalizeFirstLetter } from "../common/FirstLetterCapitalize";

const LatestProject = () => {
  const [buildingData, setBuildingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getBuildingDatas = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/buildings/get-all-buildings`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        const filteredBuildings = response?.data?.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
        setBuildingData(filteredBuildings.slice(0, 3));
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBuildingDatas();
  }, []);

  return (
    <div className="px-5 sm:px-10 py-10 tracking-wide">
      <p className="text-primaryColor text-lg sm:text-xl">Some Properties</p>

      <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
        <h2 className="text-2xl sm:text-3xl md:text-4xl text-center sm:text-left">
          Find Your Perfect Property with
        </h2>
        <button
          onClick={() => navigate("/properties")}
          className="mt-4 sm:mt-0 flex items-center gap-2 text-primaryColor hover:text-primaryBtnHoverColor"
        >
          Explore <FaArrowRightLong size={25} />
        </button>
      </div>

      {
      loading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {[1, 2, 3].map((ele) => (
            <div
              role="status"
              class="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-300"
            >
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-300">
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-300 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-300 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
              <div class="flex items-center mt-4">
                <svg
                  class="w-10 h-10 me-3 text-gray-200 dark:text-gray-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
                <div>
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-32 mb-2"></div>
                  <div class="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                </div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8 cursor-pointer">
          {buildingData.map((ele) => (
            <div
            onClick={() => {
              navigate(`/property/${encodeURIComponent(ele?.generalInfo?.BuildingsName.replace(/\s+/g, '-'))}/${ele?._id}`, { state: { ele } });
              window.scrollTo(0, 0);
            }}
              className="rounded-lg overflow-hidden shadow-lg"
            >
              <div className="h-60 overflow-hidden">
                <img
                  src={ele?.BuildingsImages[0]}
                  alt={ele?.generalInfo?.BuildingsName+" "+"Image"}
                  className="h-full w-full object-cover hover:scale-125 transition duration-1000"
                />
              </div>
              <div className="p-4">
                <h1 className="text-lg font-semibold">
                  {capitalizeFirstLetter(ele?.generalInfo?.BuildingsName)}
                </h1>
                <div className="flex gap-2 mt-2 items-center">
                  <CiLocationOn size={20} className="text-primaryColor" />
                  <p className="text-secondaryTextColor text-xs sm:text-sm">
                    {capitalizeFirstLetter(ele?.locationDetails?.address)},
                    {capitalizeFirstLetter(ele?.locationDetails?.city)},
                    {capitalizeFirstLetter(ele?.locationDetails?.state)},
                    {capitalizeFirstLetter(ele?.locationDetails?.country)},
                    {capitalizeFirstLetter(ele?.locationDetails?.zipCode)}
                  </p>
                </div>

                <div className="flex items-center gap-4 mt-6 mb-4">
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <FaTowerObservation className="text-primaryColor" />
                    <p>{ele?.physicalCharacteristics?.numberOfTowers || 0}</p>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <FaHouseMedical className="text-primaryColor" />
                    <p>{ele?.units?.length}</p>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <LiaCertificateSolid className="text-primaryColor" />
                    <p>{ele?.generalInfo?.approval}</p>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-secondaryTextColor">
                    <IoCubeOutline className="text-primaryColor" />
                    <p>{ele?.physicalCharacteristics?.totalArea || 0} acre</p>
                  </div>
                </div>
                <hr />
                <div className="flex items-center justify-between mt-4">
                  <h1 className="font-bold">
                    {ele?.generalInfo?.currentStatus}
                  </h1>
                  <button className="flex items-center gap-2 bg-primaryColor text-white hover:bg-primaryBtnHoverColor px-4 py-1 rounded-md">
                    Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LatestProject;
